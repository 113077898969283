export enum RotuloType {
  DESCOMPLICANDO_CIENCIA = "descomplicando_ciencia",
  DESAFIO_CLINICO = "desafio_clinico",
  OPINIAO_ESPECIALISTA = "opiniao_do_especialista",
  DEEP_DIVE = "deep_dive",
  FAST_CHALLENGE = "fast_challenge",
  TESTE_CONHECIMENTO = "teste_conhecimento",
  REUNIAO_CIENTIFICA = "reuniao_cientifica",
  EINSTEIN_PRACTICE = "einstein_practice",
  PILULA_CONHECIMENTO = "pilula_de_conhecimento",
  CONEXAO_IDEIAS = "conexao_de_ideias",
  CURSOS_ONLINE = "cursos_online",
  PATHWAYS = "pathways",
  PLAYLIST = "playlist",
}

export interface Categoria {
  tipo?: RotuloType;
  nome: string;
}

export const formatarNomeCategoria = (nome: string | undefined) => {
  if (!nome) {
    return "";
  }

  nome = nome.indexOf(" - ") !== -1 ? nome.split(" - ")[0] : nome;

  return nome;
};

export const getCategoriaPorNome = (nome: string) => {
  if (!nome) {
    return undefined;
  }
  const tag = getListaCategorias().find((item) => item.nome === nome);

  if (!tag) {
    return undefined;
  }

  return formatarNomeCategoria(tag.nome);
};

export const getTagCategoriaPorTipo = (nome: string) => {
  if (!nome) {
    return undefined;
  }

  const _nameFormatted = formatarNomeCategoria(nome);

  const rotulo = getListaCategorias().find(
    (item: Categoria) =>
      _nameFormatted
        .toLocaleLowerCase()
        .indexOf(item.nome.toLocaleLowerCase()) !== -1,
  );

  if (!rotulo) {
    return undefined;
  }

  return formatarNomeCategoria(rotulo.tipo);
};

export const getListaCategorias = () => {
  const list: Categoria[] = [];
  list.push({
    nome: "Descomplicando Ciência",
    tipo: RotuloType.DESCOMPLICANDO_CIENCIA,
  });

  list.push({
    nome: "Desafio Clínico",
    tipo: RotuloType.DESAFIO_CLINICO,
  });

  list.push({
    nome: "Desafios Clínicos",
    tipo: RotuloType.DESAFIO_CLINICO,
  });

  list.push({
    nome: "Opinião do Especialista",
    tipo: RotuloType.OPINIAO_ESPECIALISTA,
  });

  list.push({
    nome: "Deep Dive",
    tipo: RotuloType.DEEP_DIVE,
  });

  list.push({
    nome: "Fast Challenge",
    tipo: RotuloType.FAST_CHALLENGE,
  });

  list.push({
    nome: "Teste seus Conhecimentos",
    tipo: RotuloType.TESTE_CONHECIMENTO,
  });

  list.push({
    nome: "Reunião Científica",
    tipo: RotuloType.REUNIAO_CIENTIFICA,
  });

  list.push({
    nome: "Einstein Practice",
    tipo: RotuloType.EINSTEIN_PRACTICE,
  });

  list.push({
    nome: "Pílula de Conhecimento",
    tipo: RotuloType.PILULA_CONHECIMENTO,
  });

  list.push({
    nome: "Conexão de Ideias",
    tipo: RotuloType.CONEXAO_IDEIAS,
  });

  list.push({
    nome: "Cursos Online",
    tipo: RotuloType.CURSOS_ONLINE,
  });

  list.push({
    nome: "Pathways",
    tipo: RotuloType.PATHWAYS,
  });

  list.push({
    nome: "Playlist",
    tipo: RotuloType.PLAYLIST,
  });

  return list;
};
