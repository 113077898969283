import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { twMerge } from "tailwind-merge";

interface BreadcrumbItemProps {
  to?: string;
  children?: ReactNode;
  active?: boolean;
  className?: string;
}


const BreadcrumbItem = ({
  children,
  active = false,
  className,
  ...props
}: BreadcrumbItemProps) => {
  const history = useHistory();

  const handleTo = () => {
    if (props.to) {
      history.replace(props.to);
    }
  };

  return (
    <>
      <li
        data-breadcrumb-item
        className={twMerge(
          cx({
            "md:!text-xs text-xxs text-white leading-none flex items-center before:text-[#cccc] before:!text-xxs before:md:!text-xs ":
              active,
          }),
          (className =
            "flex items-center before:text-[#cccc] before:!text-xxs before:md:!text-xs ")
        )}
      >
        {(props.to && (
          <span
            className={twMerge(
              "md:!text-xs !text-xxs font-normal leading-none text-white self-center before:text-[#cccc] !before:!text-xxs  before:md:!text-xs cursor-pointer hover:font-semibold focus:font-semibold",
              className
            )}
            tabIndex={0}
            role="link"
            onClick={handleTo}
            aria-label={children?.toString()}
          >
            {children}
          </span>
        )) ||
          <span className="md:!text-xs !text-xxs font-normal leading-none text-white self-center before:text-[#cccc] !before:!text-xxs  before:md:!text-xs cursor-pointer hover:font-semibold focus:font-semibold">{children}</span>}
      </li>
    </>
  );
};


export default BreadcrumbItem;
