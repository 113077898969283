import React, { useContext, useEffect, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { Input } from "../../../../shared/components/ui/input";

import { ModalService } from "../../../../shared/components/Modal";

import "./styles.scss";
import { ADEInputText } from "../../../../shared/components";
import { Button } from "../../../../shared/components/ui/button";
import EsqueceuASenha from "../../../../assets/images/esqueceu-senha.png";
import { AlertContext } from "../../../../context/UseAlert";

export const ModalSolicitaMudancaSenha: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [response, setResponse] = useState<any>({});
  const { showMessage } = useContext(AlertContext);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().email("Email inválido").required("Campo obrigatório"),
    }),
    onSubmit: (dados) => {
      fetch(`${process.env.REACT_APP_CHANGE_PASSWORD_REQUEST_URL}`, {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(dados),
      }).then((res) => {
        setResponse(res);

        if (res.status === 200) {
          setSuccess(true);
          showMessage("Link enviado para o e-mail informado.", "SUCESS");
          ModalService.hide();
        } else {
          setFailed(true);
        }
      });
    },
  });

  return (
    <>
      {!success && failed && (
        <div data-forgot-password-modal>
          <h3>
            {response.status === 404
              ? "Usuário não cadastrado"
              : `Erro inesperado (${response.status})`}
          </h3>
          <p>
            {response.status === 404
              ? "O usuário informado não existe em nossa base de cadastro."
              : "Infelizmente ocorreu um erro. Por favor, tente novamente mais tarde."}
          </p>
          <Button
            type="button"
            data-testid="btn-ok-erro"
            variant="primary"
            onClick={() => ModalService.hide()}
          >
            OK
          </Button>
        </div>
      )}

      {!success && !failed && (
        <div data-forgot-password-modal>
          <img
            src={EsqueceuASenha}
            alt="Ícone esqueci senha"
            className="mb-6 h-[79.75px] w-[120px] md:h-[106.33px] md:w-[160px]"
          />
          <h3 className="text-base font-bold leading-22 text-[#004F92]">
            Recuperar Senha
          </h3>
          <p className="mb-6 w-[250px] px-4 text-center text-sm font-normal leading-5 text-[#313335] md:w-[350px]">
            Enviaremos um e-mail com instruções de como redefinir sua senha.
          </p>
          <form
            autoComplete="off"
            name="forgot-password-request-form"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="mb-3 flex w-full flex-col">
              <label className="mx-3 w-[250px] text-sm font-normal leading-normal text-[#313335] md:w-[350px]">
                E-mail
                <Input
                  className="mt-2 h-[50px] w-full rounded-[30px] !bg-[#FAFAFC]"
                  type="email"
                  name="email"
                  placeholder="Digite seu e-mail"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched.email ? formik.errors.email : ""}
                />
              </label>
            </div>
            <Button
              data-testid="btn-enviar"
              type="submit"
              variant="primary"
              className="w-[250px] md:w-auto"
            >
              Enviar
            </Button>
          </form>
        </div>
      )}
    </>
  );
};

export default ModalSolicitaMudancaSenha;

export const mostrarModalSolicitaMudancaSenha = () => {
  ModalService.show({
    showCloseButton: true,
    closeOutsideModal: true,
    notFullScreenMobile: true,
    content: <ModalSolicitaMudancaSenha />,
  });
};
