import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import BarraInteracaoOe from "./components/BarraInteracaoOe";
import { AboutAuthors } from "./components/SobreAutores";
import { useModalQuaseLa } from "./components";

import { CardSection, Loader } from "../../shared/components";

import { OeTextoPage } from "./OeTextoPage";
import { ObjetoEducacionalVideo, OeVideoPage } from "./OeVideoPage";
import { OeInfograficoPage } from "./OeInfograficoPage";

import {
  ObjetoEducacional,
  ObjetoEducacionalEntity,
  PerfilDeAutorEntity,
  useObjetoEducacionalLazyQuery,
} from "../../generated/graphql";
import { getUserContext } from "../../context/UserContext";
import useOeVisualized from "../../hooks/useOeVisualized";
import { OesRelacionados } from "./components/OesRelacionados";

import {
  TIPO_AREA_PAGINA,
  TIPO_PAGINA,
  useBannerApi,
} from "../../hooks/useBannerApi";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

import { ModalService, BannerSection } from "../../shared/components";
import { ModalConfirmaPerfilMedico } from "./components/ModalConfirmaPerfilMedico";
import { ModalBloqueiaPerfilNaoMedico } from "./components/ModalBloqueiaPerfilNaoMedico";
import { ModalBloqueiaUsuarioNaoLogado } from "./components/ModalBloqueiaUsuarioNaoLogado";
import { ModalAcessoPermitidoLogados } from "./components/ModalAcessoPermitidoLogados";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { OeLivePage } from "./OeLivePage";
import { OePDFPage } from "./OePDFPage";
import { OePreviewDesktop, TypeOE } from "./Preview/OePreviewDesktop";
import { OePreviewMobile } from "./Preview/OePreviewMobile";
import { isMobile } from "react-device-detect";
import PodcastPage from "./OePodcastPage";
import Smarthint from "../../shared/components/Smarthint";
import Container from "../../shared/components/Container";
import { useUtils } from "../../shared/utils";
import { OeTesteConhecimento } from "./OeTesteConhecimento";
import {
  Banner,
  loadBanner,
} from "../../graphql/collections/queries/banner.query";
import { mostrarModalLogin } from "../../shared/modals/ModalLogin";
import OEMultiplosVideos from "./OEMultiplosVideos";
interface OEPageProps {
  match: any;
}

const OEPage: React.FC<OEPageProps> = ({ match }) => {
  const scrollTop = useScrollTop();
  const location = useLocation();
  const history = useHistory();
  const user = getUserContext();
  const { registerOeVisualized } = useOeVisualized();
  const gtm = useGtm();
  const { formatTitle } = useUtils();

  const [oe, setOe] = useState<ObjetoEducacionalEntity | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(true);
  const [ehRestrito, setEhRestrito] = useState<boolean | null>(null);
  const [ehMedico, setEhMedico] = useState<boolean | null>(null);
  const [ehLive, setEhLive] = useState<boolean>(
    oe?.attributes?.TipoObjeto?.data[0].attributes?.titulo === "Live",
  );
  const [listCardOesVideos, setListCardOesVideos] = useState<
    ObjetoEducacionalEntity[]
  >([]);

  const { mostrar: mostrarModalQuaseLa } = useModalQuaseLa();

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBanner({
      homePage: false,
      objetoEducacionalId: oe?.id ? Number(oe?.id) : undefined,
      areaDeConhecimentoId:
        oe?.attributes?.SubAreaConhecimento?.data
          ?.filter((sub) => {
            return !!sub.attributes?.area_de_conhecimento?.data?.id;
          })
          ?.map((a) => Number(a.attributes?.area_de_conhecimento?.data?.id)!) ||
        [],
      subAreaDeConhecimentoId:
        oe?.attributes?.SubAreaConhecimento?.data?.map((a) => Number(a.id!)) ||
        [],
      publicoAlvoId:
        oe?.attributes?.PublicoAlvo?.data?.map((a) => Number(a.id!)) || [],
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, [oe]);

  function validarOePorTipo(oe: ObjetoEducacional, path: string) {
    path = path.replace("preview", "");
    const tipos: any = {
      podcast: {
        áudio: true,
        audio: true,
        podcast: true,
      },
      quiz: {
        quiz: true,
        questionário: true,
        questionario: true,
        simulação: true,
        simulacao: true,
      },
      Questionário: {
        quiz: true,
        questionário: true,
        questionario: true,
        simulação: true,
        simulacao: true,
      },
      leitura: {
        leitura: true,
        texto: true,
      },
      video: {
        video: true,
        vídeo: true,
      },
      webcast: {
        webcast: true,
      },
      live: {
        live: true,
      },
      pdf: {
        pdf: true,
      },
      imagem: {
        imagem: true,
        infográfico: true,
        infografico: true,
      },
    };

    const tiposPorPath: any = tipos[path];
    const existeTipoNaOe = (oe.TipoObjeto?.data || []).find(
      (item) =>
        !!tiposPorPath[(item.attributes?.titulo || "").toLocaleLowerCase()],
    );

    if (!existeTipoNaOe) {
      window.location.href = "/";
      return;
    }
  }

  useEffect(() => {
    const newListCardOesVideos = oe?.attributes?.VideoAudio?.filter(
      (item, index) => index !== 0,
    )
      .map(
        (card) =>
          ({
            id: oe?.id,
            VideoId: card.id,
            attributes: {
              ImagemCapa: {
                data: card.Thumbs?.data,
              },
              ImagemPreview: {
                data: card.Thumbs?.data,
              },
              Patrocinador: {},
              Rotulos: {
                data: [],
              },
              VideoId: card.id,
              VideoAudio: [card],
              TipoObjeto: {
                data: [...(oe?.attributes?.TipoObjeto?.data || [])],
              },
              Descricao: card.Descricao,
              created_at: undefined,
              updated_at: undefined,
              Resumo: "",
              TempoMedioDuracao: card.TempoMedioDuracao || "0",
              patrocinador: undefined,
              id: oe?.id,
              Titulo: formatTitle(card.Titulo!),
              OrdemSequencial: card.OrdemSequencial,
              OesAssistidos: {
                data: (oe?.attributes?.OesAssistidos?.data || []).filter(
                  (item) =>
                    item.attributes?.VideoAudioId?.toString() ===
                    card.id.toString(),
                ),
              },
            },
          }) as ObjetoEducacionalEntity,
      )
      .sort((a: any, b: any) =>
        a.OrdemSequencial < b.OrdemSequencial ? -1 : 1,
      );

    if (newListCardOesVideos) {
      setListCardOesVideos([...newListCardOesVideos!]);
    } else {
      setListCardOesVideos([]);
    }
  }, [oe]);

  const [GetOeById] = useObjetoEducacionalLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (
        !response.objetoEducacional ||
        response.objetoEducacional.data?.attributes?.Status !== "Ativo" ||
        response.objetoEducacional.data?.attributes?.publishedAt === null
      ) {
        if (response?.objetoEducacional?.data?.attributes?.Status === "ResidenciaMedica" &&
          response?.objetoEducacional?.data?.attributes?.publishedAt !== null) {
          history.push("/prepara-einstein");
        }
        history.push("/");
        return;
      }

      registerOeVisualized(response.objetoEducacional.data.id!);

      setOe(response.objetoEducacional.data as ObjetoEducacionalEntity);
      setEhLive(
        response.objetoEducacional.data?.attributes?.TipoObjeto?.data[0]
          .attributes?.titulo === "Live",
      );

      // GTM doc linha 85!!!
      gtm.push(
        EDataLayerEventCategory.OE_PAGEVIEW,
        EDataLayerEventAction.NAVEGACAO,
        {
          label:
            formatTitle(response.objetoEducacional?.data.attributes.Titulo) ||
            "",
          idOe: response.objetoEducacional?.data.id,
        },
      );

      setTimeout(() => setLoading(false), 700);
    },
    onError: () => history.push("/"),
  });

  useEffect(() => {
    if (!match.params.id) {
      history.push("/");
      return;
    }

    scrollTop();
    GetOeById({
      variables: {
        id: match.params.id,
      },
    });
  }, [history.location, match.params]);

  const renderSwitch = () => {
    if (!oe) {
      window.location.href = "/";
      return;
    }

    const regex =
      /\/oe\/(.*)\/(questionário|quiz|podcast|video|webcast|live|leitura|imagem|pdf)/;
    let path = window.location.pathname;
    path = path.replace(regex, "$2");
    path = path.replaceAll(/\//g, "");

    if (path.includes("video")) {
      path = path.replace(/[^video]/g, "");
    }

    if (path.includes("webcast")) {
      path = path.replace(/[^webcast]/g, "");
    }

    if (path.includes("quiz")) {
      path = path.replace(/[^quiz]/g, "");
    }

    if (path.includes("live")) {
      path = path.replace(/[^live]/g, "");
    }

    if (path.includes("pdf")) {
      path = path.replace(/[^pdf]/g, "");
    }

    validarOePorTipo(oe.attributes!, path);

    switch (path) {
      case "quiz":
      case "questionário":
        return <OeTesteConhecimento oe={oe} user={user} />;

      case "leitura":
        return (
          <OeTextoPage isPreview={false} oe={oe} user={user}></OeTextoPage>
        );

      case "leiturapreview":
        return <OeTextoPage isPreview={true} oe={oe} user={user}></OeTextoPage>;

      case "podcast":
        return <PodcastPage oe={oe} user={user} />;

      case "video":
      case "webcast":
        return <OeVideoPage oe={oe} user={user} />;

      case "live":
        return <OeLivePage oe={oe} user={user} setEhLive={setEhLive} />;

      case "imagempreview":
        return <OeInfograficoPage isPreview={true} oe={oe} user={user} />;

      case "imagem":
        return <OeInfograficoPage isPreview={false} oe={oe} user={user} />;

      case "pdf":
        return <OePDFPage oe={oe} user={user} />;
    }
  };

  /** TODO ::: criar hook para gerenciar modais de restricao medica */
  useEffect(() => {
    const user = getUserContext();

    if (!oe || !user?.id) {
      return;
    }

    setEhRestrito(!!oe.attributes?.RestricaoPublicoMedico);

    if (!user) {
      if (!!oe?.attributes?.RestricaoPublicoMedico) {
        ModalService.show({
          content: <ModalBloqueiaUsuarioNaoLogado />,
          showCloseButton: false,
        });
      }

      return;
    }

    const _ehMedico =
      !!user.attributes?.PerfilProfissao &&
      !!user.attributes?.PerfilProfissao.data.length &&
      !!user.attributes?.PerfilProfissao.data[0].attributes?.PossuiCRM;

    setEhMedico(_ehMedico);

    if (!!_ehMedico && !!oe.attributes?.RestricaoPublicoMedico) {
      ModalService.show({
        content: <ModalConfirmaPerfilMedico />,
        showCloseButton: false,
      });
      return;
    }

    if (!_ehMedico && !!oe.attributes?.RestricaoPublicoMedico) {
      ModalService.show({
        content: <ModalBloqueiaPerfilNaoMedico />,
        showCloseButton: true,
        closeOutsideModal: true,
        notFullScreenMobile: true
      });
    }
  }, [oe?.id]);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <div data-oe-page>
        {!!!user && <ModalAcessoPermitidoLogados />}
        {!!oe && (
          <>
            {ehRestrito &&
              !ehMedico &&
              (!isMobile ? (
                <OePreviewDesktop oe={oe} user={user} type={TypeOE.Texto} />
              ) : (
                <OePreviewMobile oe={oe} user={user} type={TypeOE.Texto} />
              ))}

            {(!ehRestrito || (ehRestrito && ehMedico)) && renderSwitch()}

            <BarraInteracaoOe oe={oe} user={user} live={ehLive} />
            {/*
            {!!listCardOesVideos && !!listCardOesVideos.length && (
              <section className="w-full py-10">
                <Container>
                  <CardSection
                    title="Episódios"
                    items={listCardOesVideos as ObjetoEducacionalVideo[]}
                    showAllSee={true}
                  ></CardSection>
                </Container>
              </section>
            )} */}

            {oe.attributes?.Autores && !!oe.attributes?.Autores.data.length && (
              <AboutAuthors
                oe={oe}
                Autores={
                  (oe.attributes?.Autores?.data as PerfilDeAutorEntity[]) || []
                }
              />
            )}

            <OesRelacionados oe={oe} />

            <Smarthint oe={oe} userID={user?.id} />

            {(() => {
              let bannersAcimaRodape = banners?.filter(
                (a) =>
                  a.tipoPagina === TIPO_PAGINA.OE &&
                  a.areaPagina === TIPO_AREA_PAGINA.ACIMA_RODAPE &&
                  ((!!user?.id && a.ocultarLogado === false) || !user?.id),
              );

              return (
                <>
                  {!!bannersAcimaRodape && !!bannersAcimaRodape.length && (
                    <div
                      data-banner
                      // data-banner-show-patrocinado
                      data-banner-rodape
                      className="mb-4 mt-4"
                    >
                      <Container>
                        <BannerSection
                          items={bannersAcimaRodape}
                          fixo={false}
                        />
                      </Container>
                    </div>
                  )}
                </>
              );
            })()}
          </>
        )}
      </div>
    </>
  );
};

export default OEPage;
