import Vimeo from "@u-wave/react-vimeo";
import {
  ObjetoEducacionalEntity,
  ResidenciaMedicaEntity,
} from "../../../generated/graphql";
import OePDFWatch from "../../OEPage/OePDFPage/OePDFWatch";
import { criarTituloSecaoTexto } from "../../OEPage/OeInfograficoPage/components/OeInfograficoTexto";
import { QuizComponent } from "../../OEPage/OeQuizPage/components/Quiz";
import Flashcard from "../../../shared/components/Flashcard";
import { Button } from "../../../shared/components/ui/button";

export default function ApresentacaoConteudo({
  objetoEducacionalSelecionado,
  objetoEducacionalConcluido,
  handleTimeUpdateRef,
  handleFinish,
  handlePlayEvent,
  loadingConsumoModulo,
  _oeAssistido,
  _user,
  video,
  ext,
  current,
  residenciaMedica,
  handleNextOe,
  isShowProximaAula,
}: {
  objetoEducacionalSelecionado: ObjetoEducacionalEntity | null;
  objetoEducacionalConcluido: Boolean;
  handleTimeUpdateRef: any;
  handleFinish: any;
  handlePlayEvent: any;
  loadingConsumoModulo: boolean;
  _oeAssistido: any;
  _user: any;
  video: string;
  ext: string[];
  current?: number;
  residenciaMedica: ResidenciaMedicaEntity;
  isShowProximaAula: boolean;
  handleNextOe: (
    objetoEducacionalSelecionado: ObjetoEducacionalEntity | null,
  ) => void;
}) {
  return (
    <div className="w-full overflow-y-auto">
      {!objetoEducacionalSelecionado && (
        <img
          src={
            residenciaMedica?.attributes?.imagem_preview?.data?.attributes?.url
          }
          alt={
            residenciaMedica?.attributes?.imagem_preview?.data?.attributes?.name
          }
          className="h-[300px] w-full rounded-xl bg-no-repeat object-cover object-center md:h-auto"
        />
      )}
      {objetoEducacionalSelecionado?.attributes?.TipoObjeto?.data.map(
        (tipoObjeto) => {
          if (tipoObjeto?.attributes?.titulo === "Vídeo") {
            return (
              (
                <>
                  <Vimeo
                    key={objetoEducacionalSelecionado?.id}
                    id={objetoEducacionalSelecionado?.id}
                    pip={true}
                    showTitle={false}
                    start={_oeAssistido?.attributes?.segundos || 0}
                    onTimeUpdate={(ev: any) => {
                      if (
                        objetoEducacionalSelecionado?.attributes?.VideoAudio &&
                        objetoEducacionalSelecionado?.attributes?.VideoAudio
                          .length > 0
                      ) {
                        localStorage.setItem(
                          "UPDATE_VIDEO",
                          JSON.stringify({
                            id: _oeAssistido?.id,
                            infograficoId: _oeAssistido?.attributes?.ImagemOeId,
                            leituraId: _oeAssistido?.attributes?.LeituraOeId,
                            oe: objetoEducacionalSelecionado.id,
                            partialDuration: parseInt(
                              ev.seconds.toString().split(".")[0],
                            ),
                            totalDuration: parseInt(
                              ev.duration.toString().split(".")[0],
                            ),
                            user: _user?.id,
                            videoAudioId: objetoEducacionalSelecionado
                              ?.attributes?.VideoAudio[0].id as any,
                          }),
                        );
                      }
                      handleTimeUpdateRef.current(
                        ev,
                        objetoEducacionalSelecionado,
                      );
                    }}
                    onEnd={(e: any) => handleFinish(e, undefined)}
                    // onPause={(e: any) => handleStartStop(e)}
                    onPlay={(e: any) => handlePlayEvent(e)}
                    controls={true}
                    autoplay={false}
                    speed={true}
                    video={video}
                    responsive
                    className="relative overflow-hidden rounded-xl"
                  />
                  {objetoEducacionalConcluido && isShowProximaAula && (
                    <div className="flex w-full justify-end">
                      <Button
                        data-testid="btn-ir-para-proxima-aula"
                        type="button"
                        className="button button-primary !my-4 self-end"
                        onClick={() =>
                          handleNextOe(objetoEducacionalSelecionado)
                        }
                      >
                        Ir para próxima aula
                      </Button>
                    </div>
                  )}
                </>
              ) || <></>
            );
          }
          if (
            tipoObjeto?.attributes?.titulo === "PDF" &&
            objetoEducacionalSelecionado?.attributes?.PDF &&
            objetoEducacionalSelecionado?.attributes?.PDF.length > 0
          ) {
            return (
              <div className="!mb-5">
                <OePDFWatch
                  residenciaMedica={true}
                  pdf={objetoEducacionalSelecionado?.attributes?.PDF[0]}
                  idOe={objetoEducacionalSelecionado?.id!}
                />
                <div className="flex w-full justify-end">
                  <Button
                    data-testid="btn-concluir-conteudo"
                    disabled={loadingConsumoModulo}
                    type="button"
                    variant="primary"
                    onClick={(ev) => handleFinish(ev, undefined)}
                  >
                    {loadingConsumoModulo
                      ? "Concluindo..."
                      : "Concluir Conteúdo"}
                  </Button>
                </div>
              </div>
            );
          }
          if (tipoObjeto?.attributes?.titulo === "Imagem") {
            return objetoEducacionalSelecionado?.attributes?.Infografico &&
              !ext[ext.length - 1].toLowerCase().includes("pdf") ? (
              <div
                className="!mb-5"
                data-text-container={
                  objetoEducacionalSelecionado?.attributes?.TipoObjeto?.data.find(
                    (item) =>
                      item.attributes?.titulo?.toLocaleLowerCase() === "imagem",
                  )
                    ? "infografico"
                    : ""
                }
              >
                {objetoEducacionalSelecionado.attributes?.Infografico?.map(
                  (info) => (
                    <div key={info.id} className={`zoom zoom-${current}x`}>
                      {objetoEducacionalSelecionado.attributes?.SecoesTexto!.map(
                        (text, index) => (
                          <div key={text?.id}>
                            <h2>{criarTituloSecaoTexto(index)}</h2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: text?.Secao,
                              }}
                            />
                          </div>
                        ),
                      )}

                      <div data-infografico-image>
                        {info.Imagem?.data?.attributes?.url && (
                          <img
                            src={info.Imagem.data.attributes?.url}
                            alt={info.Titulo!}
                            title={info.Titulo!}
                          />
                        )}
                      </div>
                    </div>
                  ),
                )}
                <div data-button-finish>
                  <Button
                    data-testid="btn-concluir-conteudo"
                    disabled={loadingConsumoModulo}
                    type="button"
                    variant="primary"
                    onClick={(ev) => handleFinish(ev)}
                  >
                    {loadingConsumoModulo
                      ? "Concluindo..."
                      : "Concluir Conteúdo"}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <OePDFWatch
                  residenciaMedica={true}
                  pdf={
                    objetoEducacionalSelecionado?.attributes?.Infografico
                      ? objetoEducacionalSelecionado.attributes.Infografico[0]!
                          .Imagem
                      : undefined
                  }
                  idOe={objetoEducacionalSelecionado.id!}
                />
                <div data-button-finish>
                  <Button
                    data-testid="btn-concluir-conteudo"
                    disabled={loadingConsumoModulo}
                    type="button"
                    variant="primary"
                    onClick={(ev) => handleFinish(ev, undefined)}
                  >
                    {loadingConsumoModulo
                      ? "Concluindo..."
                      : "Concluir Conteúdo"}
                  </Button>
                </div>
              </>
            );
          }
          if (tipoObjeto?.attributes?.titulo === "Questionário") {
            return (
              <QuizComponent
                oe={objetoEducacionalSelecionado}
                user={_user}
                onFinish={() => handleFinish()}
                IsResidenciaMedica={true}
              />
            );
          }
          if (tipoObjeto?.attributes?.titulo === "Flashcard") {
            return (
              <Flashcard objetoEducacional={objetoEducacionalSelecionado} />
            );
          }
        },
      )}
    </div>
  );
}
