import React, { ReactNode, useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Notificacao from "../../assets/svgs/icone-notificacao.svg";

import {
  ObjetoEducacionalEntity,
  ResidenciaMedicaEntity,
  useGetResidenciaMedicasHomeQuery,
  useGetUserDetalhesQuery,
} from "../../generated/graphql";

import { MeusAutoresPage } from "./components/MeusAutores";
import { CarroselDeclaracao } from "./components";
import EditProfile from "./components/EditProfile";
import ViewHistoryNav from "./components/ViewHistoryNav";
import HistoryContent from "./components/HistoryContent";

import { CardSection, CardContainer, Loader } from "../../shared/components";

import { getUserWithJwtContext, IUserContext } from "../../context/UserContext";

import useOeHistory from "../../hooks/useOeHistory";
import useOeFavoritado from "../../hooks/useOeFavoritado";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import { useValidacaoDadosUsuario } from "../../hooks/useValidacaoDadosUsuario";

import * as Icon from "../../assets/svgs";
import "./styles.scss";
import Container from "../../shared/components/Container";
import { CardSectionResidenciaMedica } from "../../shared/components/CardSectionResidenciaMedica";
import { CardData } from "../../shared/components/CardAreaResidenciaMedica";

export type TProps = { children?: ReactNode } & {
  match?: any;
};

export const MeuPerfilPage: React.FC<any> = (props) => {
  const history = useHistory();
  const [habilitaResumo, setHabilitaResumo] = useState(true);
  const scrollIntoViewRef = useRef<HTMLDivElement>(null);

  const { user } = getUserWithJwtContext() as IUserContext;

  const { data: oeHistory, loading } = useOeHistory();
  const { data: oesFavoritados } = useOeFavoritado({
    userId: !!user ? user.id! : "",
  });

  const { validadoDadosUsuario } = useValidacaoDadosUsuario();

  const [residenciaMedicas, setResidenciaMedicas] = useState<
    ResidenciaMedicaEntity[]
  >([]);

  const { loading: loadingResindecia } = useGetResidenciaMedicasHomeQuery({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      const { data } = response.residenciaHomePage!;
      if (data) {
        setResidenciaMedicas(data! as ResidenciaMedicaEntity[]);
      } else {
        setResidenciaMedicas(data);
      }
    },
  });

  const gtm = useGtm();

  useEffect(() => {
    gtm.push(
      EDataLayerEventCategory.PAGE_VIEW,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: "meu-perfil",
      }
    );
  }, []);

  useEffect(() => {
    if (!user) {
      history.push("/");
    }

    const heightScreen = window.screen.availHeight;

    if (
      heightScreen <= 1024 &&
      (props?.match?.path.includes("/historico") ||
        props?.match?.path.includes("/autores"))
    ) {
      scrollIntoViewRef.current?.scrollIntoView();
    }
  }, [user, history, props?.match?.path]);

  useEffect(() => {
    const pathName = props?.match?.path || '';

    if (
      pathName.includes("/informacoes-pessoais") &&
      pathName.includes("/informacoes-profissionais") &&
      pathName.includes("/preferencias-de-conteudo")
    ) {
      setHabilitaResumo(document.body.offsetWidth <= 480 ? false : true);

      return;
    }

    setHabilitaResumo(true);
  }, []);

  const { data: userDetalhes, loading: loadingUser } = useGetUserDetalhesQuery({
    skip: !user,
    fetchPolicy: "network-only",
    variables: {
      idUser: user?.id!,
    },
  });

  const renderizarPagina = () => {
    switch (props?.match?.path) {
      case "/meu-perfil/historico/videos":
      case "/meu-perfil/historico/audios":
      case "/meu-perfil/historico/textos-e-artigos":
      case "/meu-perfil/historico/quizzes-e-desafios":
      case "/meu-perfil/historico/imagens-e-infográficos":
        return <HistoryContent />;
      case "/meu-perfil/informacoes-pessoais":
      case "/meu-perfil/informacoes-profissionais":
      case "/meu-perfil/preferencias-de-conteudo":
        return (
          <>
            <div id="sectionIdProfile">
              <Container className="pb-8">
                <CardContainer shadow>
                  <EditProfile path={props?.match?.path} />
                </CardContainer>
              </Container>
            </div>
          </>
        );
      case "/meu-perfil/autores":
        return <MeusAutoresPage />;

      default:
        return (
          <Container className="py-8">
            {!loadingUser &&
              userDetalhes?.userResidenciaDetalhes?.data &&
              userDetalhes?.userResidenciaDetalhes?.data?.length > 0 && (
                <CardSectionResidenciaMedica
                  loading={loadingResindecia}
                  items={residenciaMedicas}
                  title="Prepara Einstein"
                  linkUrl="/prepara-einstein"
                  showAllSee={true}
                />
              )}

            <CarroselDeclaracao />

            {!!oesFavoritados?.length && (
              <div>
                <CardSection
                  title="Favoritos"
                  linkUrl="/favoritos"
                  items={(oesFavoritados as ObjetoEducacionalEntity[]) || []}
                  showAllSee={true}
                ></CardSection>
              </div>
            )}

            {!!oeHistory.length && (
              <div data-oe-cardsection>
                <CardSection
                  title="Assistir novamente"
                  linkUrl="/videos-em-alta"
                  items={oeHistory}
                  showAllSee={true}
                ></CardSection>
              </div>
            )}
          </Container>
        );
    }
  };

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <main data-my-profile>
        <h2 data-title className="hide-for-desktop">
          Academia Digital Einstein
        </h2>

        {!validadoDadosUsuario && (
          <div data-box-notificacao-perfil>
            <p>
              <img
                data-menu-notificacao-mobile
                src={Notificacao}
                alt="Notificação"
                height="20px"
                width="20px"
                style={{ height: "20px", width: "20px", display: "inline" }}
              />
              Dados de perfil incompletos. <br className="hide-for-desktop" />
              <a href="/meu-perfil/informacoes-pessoais#sectionIdProfile">
                Clique aqui
              </a>{" "}
              para completar seu cadastro.
            </p>
          </div>
        )}

        {habilitaResumo ? (
          <>
            <ViewHistoryNav path={props?.match?.path || ''} />
          </>
        ) : (
          <></>
        )}
        <div ref={scrollIntoViewRef}></div>
        {renderizarPagina()}
      </main>
    </>
  );
};
