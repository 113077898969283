import React from "react";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../shared/components/ui/carousel";
// import { api } from "@/data/api";
import { Button } from "../../../shared/components/ui/button";
import {
  getDescricaoTipoObjeto,
  getIconeTipoObjeto,
} from "../../../shared/utils/tipo-objeto";
import { useEffect, useState } from "react";
import Rotulo from "../../../shared/components/rotulos";
import { RotuloType } from "../../../shared/utils/rotulo";
import {
  formatCustomDate,
  formatarDuracao,
} from "../../../shared/utils/format";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import Container from "../../../shared/components/Container";
import ObjetoEducacionalBanner from "../components/oe-banner-home";
import Autoplay from "embla-carousel-autoplay";

import TimeBlue from "../../../assets/svgs/new/time-blue.svg";
import CalendarBlue from "../../../assets/svgs/new/calendar-blue.svg";
import Favorite from "../../../assets/svgs/new/favorite.svg";
import { useHistory } from "react-router";
import PodCastDestaqueSemana from "./podcast-destaque-semana";
import {
  DestaqueSemanaModel,
  DestaqueSemanaResponse,
} from "../../../models/DestaqueSemana";
import { Favoritar } from "../../../shared/components/Favoritar";
import { Clock4 } from "lucide-react";

async function getSuasAreasInteresse(): Promise<DestaqueSemanaResponse> {
  const response = await fetchApi("home/destaques-da-semana", "GET").then(
    (res) => {
      if (res.ok) {
        return res.json();
      } else {
        return [
          {
            oesDestaque: [],
            podcast: null,
            quiz: null,
          },
        ];
      }
    },
  );

  return response;
}

export default function DestaqueSemana() {
  const [destaqueSemana, setDestaqueSemana] = useState<DestaqueSemanaResponse>(
    {} as DestaqueSemanaResponse,
  );

  useEffect(() => {
    getSuasAreasInteresse().then((data) => {
      setDestaqueSemana(data);
    });
  }, []);

  const history = useHistory();

  const formatarUrl = (oeType: number, oeId: number) => {
    switch (oeType) {
      case 3:
        return `/oe/${oeId}/podcast/`;
      case 2:
        return `/oe/${oeId}/imagem/preview`;
      case 5:
        return `/oe/${oeId}/quiz`;
      case 4:
        return `/oe/${oeId}/leitura/preview/`;
      case 7:
        return `/oe/${oeId}/live`;
      case 6:
        return `/oe/${oeId}/video`;
      case 8:
        return `/oe/${oeId}/pdf`;
      default:
        return "";
    }
  };

  const handleClick = (model: DestaqueSemanaModel) => {
    history.replace(formatarUrl(model.tipoObjetoId, model.id));
  };

  const plugin = React.useRef(
    Autoplay({ delay: 5000, playOnInit: true, stopOnFocusIn: true }),
  );

  return (
    <>
      <div className="bg-[#F5F8FF]">
        <Carousel
          className="w-full"
          opts={{
            loop: true,
          }}
          plugins={[plugin.current]}
        >
          {!destaqueSemana?.quiz ? (
            <div className="relative h-[24.125rem] w-full space-y-5 overflow-hidden rounded-2xl bg-slate-100 md:h-[25rem] tablet:h-[16.25rem] ">
              <div className="h-full w-full  -translate-x-full animate-[shimmer_2s_infinite] rounded-2xl bg-gradient-to-r from-transparent via-gray-400/20 to-transparent"></div>
            </div>
          ) : (
            <>
              <CarouselContent className="-ml-2">
                {destaqueSemana?.oesDestaque?.map(
                  (area: DestaqueSemanaModel, index: number) => (
                    <CarouselItem key={index} className="-pl-1">
                      <ObjetoEducacionalBanner {...area} />
                    </CarouselItem>
                  ),
                )}
              </CarouselContent>
              <CarouselPrevious
                data-testid="btn-previous-destaque"
                color=""
                height="h-[24.125rem] md:h-[25rem] tablet:h-[16.25rem]"
                className="banner left-1 lg:left-[4.375rem]"
              />
              <CarouselNext
                data-testid="btn-next-destaque"
                color=""
                height="h-[24.125rem] md:h-[25rem] tablet:h-[16.25rem]"
                className="banner right-1 md:right-[4.375rem] tablet:right-0"
              />
              <CarouselBullets
                className="absolute  !bottom-8 left-0 right-0 mx-auto"
                bulletType="tag"
              />
            </>
          )}
        </Carousel>
        <Container className=" py-9 md:py-20 tablet:py-10">
          <h4 className="gradiente-einstein mb-6 text-xxl font-semibold leading-22">
            Destaques da semana
          </h4>
          <div className="flex h-auto flex-col gap-3 md:flex-row md:gap-6 tablet:gap-3">
            {/* FAST CHALLENGE */}

            {!destaqueSemana?.quiz ? (
              <div className="relative h-64 w-full space-y-5 overflow-hidden rounded-2xl bg-slate-100 md:h-[17.626rem] tablet:!h-80 ">
                <div className="h-full w-full  -translate-x-full animate-[shimmer_2s_infinite] rounded-2xl bg-gradient-to-r from-transparent via-gray-400/20 to-transparent"></div>
              </div>
            ) : (
              <>
                <div className="flex  h-64 w-full flex-col gap-6 rounded-xl bg-[#FFFFF]  bg-white-200 p-6 md:h-[17.626rem] tablet:!h-80 tablet:gap-3">
                  <div className="flex w-full justify-between">
                    <Rotulo
                      rotuloName={destaqueSemana?.quiz?.descricaoRotulo}
                      id={destaqueSemana?.quiz?.idRotulo}
                    />
                    <Favoritar
                      idOe={destaqueSemana?.quiz?.id?.toString() || "0"}
                      color="blue"
                      item={{
                        id: destaqueSemana?.quiz?.id?.toString()!,
                        titulo: destaqueSemana?.quiz.titulo!,
                      }}
                    />
                  </div>

                  <div className="flex h-full flex-col gap-2 md:gap-3 tablet:gap-2">
                    <div className="flex h-auto flex-row gap-2 tablet:flex-wrap tablet:!gap-[0.125rem]">
                      <div className="flex items-center gap-[3.3px] md:gap-1 tablet:gap-[2.45px]">
                        {getIconeTipoObjeto(
                          destaqueSemana?.quiz?.tipoObjetoId,
                          "#004F92",
                          24,
                          "h-5 w-5 md:h-6 md:w-6 tablet:h-[14.36px] tablet:w-[14.36px]",
                        )}
                        <span className="text-[10px] font-semibold leading-3 text-black-900 md:text-[11px] md:leading-4 tablet:text-8 tablet:leading-[9.75px]">
                          {getDescricaoTipoObjeto(
                            destaqueSemana?.quiz?.tipoObjetoId,
                          )}
                        </span>
                      </div>
                      <div className="flex items-center  gap-[3.3px] md:gap-1 tablet:gap-[2.45px]">
                        <img
                          src={CalendarBlue}
                          alt="icone calendario azul"
                          aria-hidden="true"
                          width={19.78} //32
                          height={19.78} //32
                          className="h-5 w-5 md:h-6 md:w-6 tablet:h-[14.36px] tablet:w-[14.36px]"
                        />
                        <span className="text-[10px] font-semibold leading-3 text-black-900 md:text-[11px] md:leading-4 tablet:text-8 tablet:leading-[9.75px]">
                          {destaqueSemana?.quiz?.dataProducao &&
                            formatCustomDate(
                              destaqueSemana?.quiz?.dataProducao,
                            )}
                        </span>
                      </div>

                      <div className="flex items-center  gap-[3.3px] md:gap-1 tablet:gap-[2.45px]">
                        <Clock4 color="#004F92" size={18}  aria-hidden="true" />
                        <span className="text-[10px] font-semibold leading-3 text-black-900 md:text-[11px] md:leading-4 tablet:text-8 tablet:leading-[9.75px]">
                          {parseInt(destaqueSemana?.quiz?.tempoMedioDuracao) &&
                            formatarDuracao(
                              parseInt(destaqueSemana?.quiz?.tempoMedioDuracao),
                            )}
                        </span>
                      </div>
                    </div>
                    <div className="flex h-full flex-col items-start gap-2 md:gap-3 tablet:gap-[6px]">
                      <h4 className="text-xs font-semibold leading-[14.63px] text-black-900 md:text-sm md:leading-18 tablet:text-[10px] tablet:leading-3">
                        {destaqueSemana?.quiz?.titulo}
                      </h4>
                      <p className="text-xs font-normal leading-[14.63px] text-black-900 md:text-sm md:leading-18 tablet:text-[10px] tablet:leading-[12.19px]">
                        {destaqueSemana?.quiz?.resumo}
                      </p>
                    </div>
                  </div>

                  <div className="flex h-auto">
                    <Button
                      data-testid="button-fastchallenge"
                      onClick={() => handleClick(destaqueSemana.quiz)}
                    >
                      Responder
                    </Button>
                  </div>
                </div>
              </>
            )}

            {/* PATHWAYS */}

            {!destaqueSemana?.quiz ? (
              <div className="relative h-64 w-full space-y-5 overflow-hidden rounded-2xl bg-slate-100 md:h-[17.626rem] tablet:!h-80 ">
                <div className="h-full w-full  -translate-x-full animate-[shimmer_2s_infinite] rounded-2xl bg-gradient-to-r from-transparent via-gray-400/20 to-transparent"></div>
              </div>
            ) : (
              <>
                <div className="flex h-64 w-full flex-col gap-[1.1043rem] rounded-xl bg-white-200 p-4  md:h-[17.626rem] md:gap-6 md:p-6 tablet:!h-80">
                  <div className="flex w-full justify-between">
                    <Rotulo rotuloName={RotuloType.PATHWAYS} id={0} />
                  </div>
                  <div className="flex h-full flex-col gap-2">
                    <h4 className="text-xs font-semibold leading-[14.63px] text-black-900 md:text-sm md:leading-18 tablet:text-[10px] tablet:leading-3">
                      Protocolos Médicos
                    </h4>
                    <p className="text-xs font-normal leading-[14.63px] text-black-900 md:text-sm md:leading-18 tablet:text-[10px] tablet:leading-[12.19px]">
                      Conjunto de documentos com o objetivo de orientar as
                      decisões e os critérios de diagnóstico, manejo e
                      tratamento em áreas específicas da saúde.
                    </p>
                  </div>
                  <div className="flex h-auto">
                    <Button
                      data-testid="btn-ver-todos-protocolos-medicos"
                      onClick={() =>
                        history.replace("/submetodos-ensino/pathways/1")
                      }
                    >
                      Ver todos
                    </Button>
                  </div>
                </div>
              </>
            )}

            {/* PODCAST */}
            <PodCastDestaqueSemana podcast={destaqueSemana?.podcast} />
          </div>
        </Container>
      </div>
    </>
  );
}
