import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  RotuloEntity,
  SubRotulo,
  SubRotuloEntity,
  useGetCategoriaLazyQuery,
  useGetSubRotulosLazyQuery,
} from "../../generated/graphql";

import { getSearchQuery, normalizeStr } from "../../helpers/common";

import { Loader } from "../../shared/components";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import NenhumItem from "../../assets/svgs/nenhum_item.svg";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import HeaderPageBreadcrumb from "../../shared/components/HeaderPageBreadcrumb";
import { useUtils } from "../../shared/utils";
import OePesquisaCabecalho from "./OePesquisaCabecalho";
import { UserContext } from "../../context/UserContext";
import { KnowledgeArea } from "../../shared/interfaces/area.interface";
import CardObjetoEducacionalGraphQL from "../../shared/components/oe-card-graphql";
import Menu from "../../shared/components/menu";
import Container from "../../shared/components/Container";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { ObjetoEducacional, Root } from "../../models/ObjetoEducacional";
import CardObjetoEducacional from "../../shared/components/oe-card";
import { Pagination } from "@mui/material";

interface SearchProps {
  p?: string;
  t?: string;
  s?: string;
  rotuloId: number;
  page?: number;
}

async function searchObjetosEducacionais({
  p,
  t,
  s,
  rotuloId,
  page,
}: SearchProps): Promise<Root> {
  const params = {
    profissoes: Array.isArray(p) ? p : p ? [p] : [],
    tipoMidia: Array.isArray(t) ? t : t ? [t] : [],
    ordernar: s,
    rotuloId,
    pageIndex: page || 1,
    pageSize: 15,
  };

  const response = await fetchApi(
    "objeto-educacional/get-oe-rotulo",
    "POST",
    JSON.stringify(params),
  ).then((res) => {
    if (res) {
      return res.json();
    } else {
      return {
        items: [],
        totalItems: 0,
        pageIndex: 0,
        pageSize: 0,
        totalPages: 0,
      } as Root;
    }
  });

  return response;
}

interface OeCategoriaProps {
  location: any;
}
interface OeCategoriaState {
  raw: ObjetoEducacional[];
  resultado: ObjetoEducacional[];
  total: number;
  pagina: number;
  error: any;
  ordem: string;
  filters: {
    areaAtuacao?: any[];
    tipoOe?: any[];
    possuiDeclaracao: boolean;
  };
  search: string | null;
}

export const OECategoriaPage: React.FC<OeCategoriaProps> = (props) => {
  const { user } = useContext(UserContext);
  const params = useParams<{ name: string; id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [oes, setOes] = useState<ObjetoEducacional[]>([]);
  const [oesTotalPage, setOesTotalPages] = useState<number>(0);
  const [oesTotal, setOesTotal] = useState<number>(0);
  const [searchParams, setSearchParams] = useState<SearchProps>(
    {} as SearchProps,
  );
  const [rotulo, setRotulo] = useState<RotuloEntity>();
  const scrollTop = useScrollTop();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);

  const [categoria, setCategoria] = useState("");
  const gtm = useGtm();

  const [areaDeConhecimentos, setAreaDeConhecimentos] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => scrollTop(), []);

  const [subRotulos, setSubRotulos] = useState<SubRotuloEntity[]>(
    [] as SubRotuloEntity[],
  );

  const [getRotulo] = useGetCategoriaLazyQuery({
    onCompleted: (response) => {
      setRotulo(response.rotulo?.data as RotuloEntity);
    },
  });

  const [getSubRotulos] = useGetSubRotulosLazyQuery({
    onCompleted: (response) => {
      setSubRotulos(response.subRotulos?.data as SubRotuloEntity[]);
    },
  });

  useEffect(() => {
    getRotulo({
      variables: {
        id: params["id"],
      },
    });
    getSubRotulos();
  }, [params["id"]]);

  useEffect(() => {
    if (!rotulo) return;
    setCategoria(rotulo.attributes?.titulo || "");

    // GTM doc linha 69
    gtm.push(
      EDataLayerEventCategory.PAGE_VIEW,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: `categoria:${rotulo?.attributes?.titulo?.toUpperCase()}`,
      },
    );
  }, [rotulo]);

  const loadObjetos = async (search: SearchProps) => {
    setLoading(true);
    const resultado = await searchObjetosEducacionais(search || searchParams);
    setOes(resultado.items);
    setOesTotalPages(resultado.totalPages);
    setOesTotal(resultado.totalItems);
    setLoading(false);
    if (resultado.items.length > 0) {
      //setPatrocinador(resultado.items[0].);
    }
  };

  useEffect(() => {
    const queryLocation = new URLSearchParams(location.search);
    const search = {
      m: queryLocation.get("m") || undefined,
      t: queryLocation.get("t") || undefined,
      s: queryLocation.get("s") || undefined,
      rotuloId: Number(params.id!),
      page: page,
    };
    setSearchParams(search);
    loadObjetos(search);
  }, [location.search, page]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ROOT}/api/area-de-conhecimentos/v2`, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json" }),
    }).then(async (res) => {
      const _ = (await res.json()).results as KnowledgeArea[];
      setAreaDeConhecimentos(
        _.map((item) => {
          return {
            label: item.titulo!,
            value: item.id.toString()!,
          };
        }),
      );
    });
  }, []);

  return (
    <>
      <HeaderPageBreadcrumb
        itens={[
          { to: "/", title: "Home" },
          { to: "/metodos-ensino", title: "Métodos de ensino" },
        ]}
        title={categoria}
        color={normalizeStr(categoria as string)
          .toLowerCase()
          .split(" ")
          .join("-")}
      />
      <Container className="h-full min-h-screen  py-9 pb-9">
        <div className="flex flex-col items-center gap-10">
          <Menu qtdConteudos={oesTotal} typeFiltro="METODO_ENSINO" />
          {oes && oesTotalPage > 1 && loading === false && (
            <div className="hidden md:flex w-full items-end justify-end">
              <Pagination
                count={oesTotalPage}
                onChange={(e, p) => setPage(p)}
                page={page}
                variant="outlined"
                color="primary"
                siblingCount={0}
              />
            </div>
          )}
          <div className="mt-5 grid grid-cols-1 gap-6 md:mt-0 md:grid-cols-3">
            {oes?.map((_: ObjetoEducacional, index: number) => (
              <div key={index} className="p-1">
                <CardObjetoEducacional objetoEducacional={_} />
              </div>
            ))}
          </div>
          {oes && oes.length === 0 && (
            <div className="flex w-full max-w-[43.87rem] flex-col items-center justify-center text-center">
              <img src={NenhumItem} className="mb-6 h-52" />
              <span className="text-base font-semibold leading-18 text-[#004F92] md:text-xxl md:leading-26 tablet:text-xxl tablet:leading-26">
                Não encontramos conteúdos com as suas preferências
              </span>
              <span className="mt-3 text-sm font-medium leading-4 text-[#626466]  md:text-lg md:leading-22 tablet:text-lg tablet:leading-22 ">
                Parece que não encontramos conteúdos com os filtros que você
                escolheu. Tente ajustar ou selecionar novos filtros para ver
                mais opções!
              </span>
            </div>
          )}
          {oes && oesTotalPage > 1 && (
            <div className="flex w-full items-end justify-end">
              <Pagination
                count={oesTotalPage}
                onChange={(e, p) => setPage(p)}
                page={page}
                variant="outlined"
                color="primary"
                siblingCount={0}
              />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};
