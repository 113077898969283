import React, { MouseEventHandler } from "react";

import ZoomInIcon from "../../../../assets/svgs/toolbar-zoom-in-icon.svg";
import ZoomOutIcon from "../../../../assets/svgs/toolbar-zoom-out-icon.svg";

import { Favoritar } from "../../../../shared/components";

import "./styles.scss";

interface BarraZoomProps {
  zoomIn: MouseEventHandler<HTMLDivElement>;
  zoomOut: MouseEventHandler<HTMLDivElement>;
  oeId: string;
}

export const BarraZoom: React.FC<BarraZoomProps> = (props) => {
  return (
    <div data-content-interactions>
      {/* <div>
        <Favoritar idOe={props.oeId} color="green" item={{
          id: props.oeId,
          titulo: ''
        }}></Favoritar>
      </div> */}
      <div onClick={props.zoomIn}>
        <img src={ZoomInIcon} alt="Ícone de zoom in" />
      </div>
      <div onClick={props.zoomOut}>
        <img src={ZoomOutIcon} alt="Ícone de zoom out" />
      </div>
    </div>
  );
};
