import Container from "../../../shared/components/Container";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../../shared/components/ui/breadcrumb";

import Menu from "../../../shared/components/menu";
import CapaPlaylist from "../../../assets/images/capa-playlist.png";
import Rotulo from "../../../shared/components/rotulos";
import { Loader } from "../../../shared/components";
import { Badge } from "../../../shared/components/ui/badge";
import {
  Card,
  CardContent,
  CardHeader,
} from "../../../shared/components/ui/card";
import { useContext, useEffect, useState } from "react";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import { useHistory, useLocation, useParams } from "react-router";
import { useScrollTop } from "../../../shared/hooks/useScrollTop";
import { Playlist } from "../PlaylistLista";
import { useModalPlaylist } from "../../../hooks/useModalPlaylist";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../shared/components/ui/dropdown";
import { Check, EllipsisVertical, Pencil, Trash2, X } from "lucide-react";
import { Alert } from "@mui/material";
import { AlertContext } from "../../../context/UseAlert";

import { useModalPlaylistConfirmDelete } from "../../../hooks/useModalConfirmDelete";
import { UserContext } from "../../../context/UserContext";
import { mostrarModalLogin } from "../../../shared/modals/ModalLogin";

interface PlaylistResponse {
  items: Playlist[];
  totalItems: number;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
}

interface SearchProps {
  titulo?: string;
  sort?: string;
  page?: string;
}

async function getPlaylistPersonalizada({ sort, titulo, page }: SearchProps) {

  const response = await fetchApi(
    "playlists/usuario/playlists",
    "POST",
    JSON.stringify({
      titulo: titulo || "",
      pageIndex: page,
      pageSize: "10",
      ordernar: sort,
    }),
  );

  return response.json();
}

export default function PlaylistsListaPersonalizada() {
  const [playlists, setPlaylists] = useState<PlaylistResponse>();

  const { user, loadingUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();
  const scrollTop = useScrollTop();

  useEffect(() => {
    if (user === undefined && !loadingUser) {
      mostrarModalLogin(false, () => {
        history.push('/');
      });
    }
  }, [user, loadingUser])


  useEffect(() => {

    if (!user) {
      return;
    }

    try {
      setLoading(true);
      const queryLocation = new URLSearchParams(location.search);
      const search = {
        page: queryLocation.get("page") || "1",
        categoriaId: params?.id || undefined,
        titulo: queryLocation.get("f") || "",
        sort: queryLocation.get("s") || undefined,
      };
      getPlaylistPersonalizada(search).then((data) => {
        setPlaylists(data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  }, [params, location.search]);

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
      <Loader show={loading} />
      <div
        style={{
          background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
        }}
        className="flex h-32 w-full"
      >
        <Container className="py-9 ">
          <Breadcrumb className="text-start  font-normal text-white-200">
            <BreadcrumbList className="flex justify-start text-start">
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/playlists">Playlists</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/playlists-personalizada">
                  Minhas playlists
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h2 className="leading-6.5 mt-3 text-1.5xl font-semibold normal-case text-white-200">
            {(playlists?.items?.length || 0) > 0
              ? playlists?.items[0]?.categoriaDescricao
              : ""}
          </h2>
        </Container>
      </div>
      <Container className="h-full min-h-screen py-10">
        <div className="flex flex-col gap-10">
          <Menu typeFiltro="PLAYLIST" qtdConteudos={playlists?.totalItems} />
          <div className="mt-20 md:mt-4">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              {playlists?.items?.map((playlist: Playlist) => (
                <Cardplaylist playlist={playlist} setPlaylist={setPlaylists} />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export function Cardplaylist({
  playlist,
  setPlaylist,
}: {
  playlist: Playlist;
  setPlaylist?: any;
}) {
  const history = useHistory();
  const [renomear, setRenomear] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(playlist.titulo);
  const { showMessage } = useContext(AlertContext);

  const updatePlaylist = async () => {
    if (!newName) return;

    try {
      const payload = {
        descricao: newName,
        playlistId: playlist.id,
      };

      const response: any = await fetchApi(
        "playlists/usuario/save",
        "POST",
        JSON.stringify(payload),
      );

      const newPlaylist = await response.json();

      if (newPlaylist?.value === "Sucesso") {
        setNewName("");
        setRenomear(false);
        showMessage("Playlist renomeada com sucesso!", "SUCESS");
        window.location.reload();
      } else {
        showMessage(newPlaylist.value, "ERRO");
      }
    } catch (error) {
      showMessage("Erro ao renomear a playlist.", "ERRO");
    } finally {
    }
  };

  return (
    <>
      <Card className="cursor-pointer shadow-none" key={playlist.id}>
        <CardHeader
          className="relative z-10 h-[227px] grow rounded-xl border p-6"
          onClick={() => {
            history.push(`/playlists/${playlist.id}/detalhe-personalizada`);
          }}
          role="link"
          aria-label={`Playlist ${playlist.titulo}`}
        >
          <img
            alt="imagem capa objeto educacional"
            aria-hidden="true"
            src={playlist.thumbnail || CapaPlaylist}
            width={300}
            height={150}
            className={
              "absolute left-0 top-0 h-full w-full rounded-xl object-cover object-top "
            }
          />
          <div aria-hidden="true" className="absolute bottom-2 left-1/2 -z-10 flex h-full w-[calc(100%-6%)] -translate-x-1/2  transform rounded-xl border-[1px] border-[#F5F8FF] bg-[#E8E9EB]" />
          <div aria-hidden="true" className="absolute bottom-4  left-1/2 -z-20 flex h-full w-[calc(100%-12%)] -translate-x-1/2  transform rounded-xl border-[1px] border-[#F5F8FF] bg-[#E8E9EB]" />
          <div
            className={`absolute left-[24px] top-[17.78px] max-w-sm md:left-6 md:top-6 `}
            aria-hidden="true"
          >
            <Rotulo rotuloName={"Playlist"} id={1} />
          </div>
          <Badge aria-hidden="true" className="absolute bottom-[19.78px] right-6 border-0 border-none bg-black-900">
            <span className="text-xxs font-bold text-white-200 md:text-xs">
              {`${playlist.qtdVideos} Conteúdos`}
            </span>
          </Badge>
          <div aria-hidden="true" className="absolute  left-1/2 top-1/2 flex h-[42px] w-[125px] -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-lg bg-black-900/95">
            <span className="text-xs font-semibold text-white-200">
              Reproduzir tudo
            </span>
          </div>
          {playlist?.visualizado && (
            <span aria-hidden="true" className="absolute bottom-[19.78px] left-[19.78px] text-xxs font-bold text-white-200 md:bottom-6 md:left-6 md:text-xs">
              Visualizado
            </span>
          )}
        </CardHeader>
        <CardContent className="mt-3 flex flex-row justify-between gap-[6px] p-0">
          <>
            {renomear ? (
              <div className="flex w-full flex-col p-1">
                <input
                  className="h-[35px] w-full rounded-md border p-2 text-base  font-semibold leading-5  text-black-900"
                  type="text"
                  placeholder="nome"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />
                <div className="flex flex-row justify-start gap-3 py-2  text-black-900">
                  <button
                    className="flex flex-row items-center gap-2 text-sm hover:font-semibold"
                    onClick={() => updatePlaylist()}
                  >
                    <Check className="size-4 " />
                    Renomear
                  </button>
                  <button
                    className="flex flex-row items-center gap-2 text-sm text-black-900 hover:font-semibold"
                    onClick={() => setRenomear(false)}
                  >
                    <X className="size-4" />
                    Cancelar
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col">
                  <span aria-hidden="true" className="text-base font-semibold leading-5 text-[#111316]">
                    {playlist?.titulo?.length > 30
                      ? `${playlist.titulo.substring(0, 30)}...`
                      : playlist.titulo}
                  </span>
                  <span aria-hidden="true" className="text-xxs font-semibold leading-4 text-[#626466]">
                    Confira a playlist completa
                  </span>
                </div>
                <MoreOptions
                  setRenomear={setRenomear}
                  idPlaylist={playlist.id}
                />
              </>
            )}
          </>
        </CardContent>
      </Card>
    </>
  );
}

export function MoreOptions({
  idPlaylist,
  setRenomear,
}: {
  setConfirmDelete?: any;
  setRenomear?: any;
  idPlaylist?: number;
}) {
  const modalConfirmDelete = useModalPlaylistConfirmDelete();
  const history = useHistory();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <button>
            <EllipsisVertical className="size-5" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            onClick={() => {
              setRenomear(true);
            }}
            className="flex cursor-pointer gap-3 px-3 py-2 text-xs text-black-900 hover:bg-slate-50"
            aria-label="renomear playlist"
          >
            <Pencil aria-hidden="true" className="size-5" />
            Renomear
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={async () => {
              await modalConfirmDelete.mostrar({
                idPlaylist: Number(idPlaylist),
              });
              history.push("playlists");
            }}
            aria-label="apagar playlist"
            className="flex cursor-pointer gap-3 px-3 py-2 text-xs text-black-900 hover:bg-slate-50"
          >
            <Trash2 aria-hidden="true" className="size-5" />
            Apagar playlist
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
