import * as Yup from "yup";
import { parse, isValid } from "date-fns";

import { existsUser } from "../../../../../graphql/auth/queries/user.graphql";

export const dadosUsuarioValidator = Yup.object().shape({
  nome_completo: Yup.string()
    .required("Campo obrigatório")
    .min(3, "Quantidade mínima de caracteres não atingida")
    .max(255, "Quantidade máxima de caracteres ultrapassada")
    .test({
      message: "Campo não permite valor numérico",
      test: (value) => {
        return !!value && !/[0-9]/.test(value);
      },
    })
    .test({
      name: "nome_completo",
      message: "Nome e sobrenome são obrigatórios",
      test: (value) => {
        return !!value && value.trim().split(" ").length >= 2;
      },
    }),
  data_nascimento: Yup.date()
    .transform((value, originalValue) => {
      try {
        const regexDataNascimento =
          /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
        // /^(0?[1-9]|[12][0-9]|3[01])(0?[1-9]|1[012])\d{4}$/;

        if (!regexDataNascimento.test(originalValue)) {
          return new Date();
        }

        const parsedDate = parse(originalValue, "dd/MM/yyyy", new Date());

        if (parsedDate.toString() === "Invalid Date") {
          return new Date();
        }

        const validated = isValid(parsedDate) ? parsedDate : new Date();

        return validated;
      } catch (error) {
        console.error("error data de nascimento", error);
        return new Date();
      }
    })
    .test({
      message: "Idade mínima para cadastro é 10 anos. Por favor, corrija a data de nascimento.",
      test: (value) => {
        if (!value) {
          return false;
        }

        const anoDataNascimento = value.getFullYear();
        const anoAtual = new Date().getFullYear();

        const totalAnos = anoAtual - anoDataNascimento;

        console.log(totalAnos);

        return totalAnos >= 10;
      },
    })
    .test({
      message: "Campo inválido",
      test: (value) => {
        if (!value) {
          return false;
        }

        const anoDataNascimento = value.getFullYear();
        const anoAtual = new Date().getFullYear();

        const totalAnos = anoAtual - anoDataNascimento;

        return totalAnos <= 100;
      },
    }),
  cpf: Yup.string().when("toggleOtherDocument", {
    is: false,
    then: Yup.string()
      .required("Campo obrigatório")
      .test({
        message: "Documento inválido",
        test: (Cpf) => validarCPf(Cpf),
      })
      .test({
        message: "Já existe um cadastro com este CPF",
        test: async (Cpf) => {
          const validado = validarCPf(Cpf);

          if (!Cpf || !validado) {
            return true;
          }

          let trying = 1;
          let validated = true;

          do {
            try {
              const hasUserWithCpf = await existsUser({
                field: "cpf",
                value: Cpf,
              });

              if (!!hasUserWithCpf.data.usersPermissionsUsers.data.length) {
                validated = false;
              }
              trying = 4;
            } catch (error) {
              trying++;
            }
          } while (trying <= 3);

          return validated;
        },
      }),
  }),
  documentType: Yup.string().when("toggleOtherDocument", {
    is: true,
    then: Yup.string().required("Campo obrigatório"),
  }),
  documentNumber: Yup.string().when("toggleOtherDocument", {
    is: true,
    then: Yup.string().required("Campo obrigatório"),
  }),
  celular: Yup.string()
    .required("Campo obrigatório")
    .when("toggleInternationalTelphone", {
      is: false,
      then: Yup.string().test({
        message: "Número de celular inválido",
        test: (value) => {
          return (
            // /^\([1-9]{2}\) 9 ([0-9]{4}\-[0-9]{4})$/
            !!value && /^\([1-9]{2}\) 9 ([0-9]{4}\-[0-9]{4})$/.test(value)
          );
        },
      }),
    })
    .when("toggleInternationalTelphone", {
      is: true,
      then: Yup.string().test({
        message: "Número de celular inválido",
        test: (value) => {
          return !!value && value.length >= 6;
        },
      }),
    }),
  email: Yup.string()
    .trim()
    .required("Campo obrigatório")
    .email("E-mail inválido")
    .test({
      message: "Já existe um cadastro com este email",
      test: async (email) => {
        if (!email) {
          return true;
        }

        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email.trim().replaceAll(" ", ""),
          )
        ) {
          return true;
        }

        let trying = 1;
        let validated = true;

        do {
          try {
            const promise = new Promise<any>((resolve) =>
              setTimeout(() => resolve(email.trim().replaceAll(" ", "")), 1000),
            );

            const emailPromise = await promise;

            const hasUserWithEmail = await existsUser({
              field: "email",
              value: emailPromise,
            });

            if (!!hasUserWithEmail.data.usersPermissionsUsers.data.length) {
              validated = false;
            }
            trying = 4;
          } catch (error) {
            trying++;
          }
        } while (trying <= 3);

        return validated;
      },
    }),
  password: Yup.string()
    .matches(/[a-z]/, "Necessário um caractere minúsculo")
    .matches(/[A-Z]/, "Necessário um caractere maiúsculo")
    .matches(/[0-9]/, "Necessário um caractere numérico")
    .matches(
      /[^a-zA-Z0-9]/,
      "Inclua um caractere especial (sinal de pontuação ou outro símbolo)",
    )
    .min(8, "Necessário ao menos 8 caracteres")
    .required("Campo obrigatório"),
  confirmPassword: Yup.string()
    .required("Campo obrigatório")
    .oneOf([Yup.ref("password"), null], "Senhas não coincidem"),
});

const validarCPf = (Cpf: string | undefined) => {
  Cpf = !!Cpf ? Cpf.replace(/(\.|-)/g, "") : "0";

  if (Cpf === "") {
    return false;
  }

  if (
    Cpf.length !== 11 ||
    Cpf === "00000000000" ||
    Cpf === "11111111111" ||
    Cpf === "22222222222" ||
    Cpf === "33333333333" ||
    Cpf === "44444444444" ||
    Cpf === "55555555555" ||
    Cpf === "66666666666" ||
    Cpf === "77777777777" ||
    Cpf === "88888888888" ||
    Cpf === "99999999999"
  ) {
    return false;
  }

  let add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(Cpf.charAt(i)) * (10 - i);
  }

  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(Cpf.charAt(9))) {
    return false;
  }

  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(Cpf.charAt(i)) * (11 - i);
  }

  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(Cpf.charAt(10))) {
    return false;
  }

  return true;
};
