import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";

import AuthorLinkCurriculoBlue from "../../../assets/svgs/author-link-curriculo-blue.svg";
import AuthorListContents from "../../../assets/svgs/author-list-contents.svg";
import InternetIcone from "../../../assets/svgs/www.svg";
import AuthorEmpty from "../../../assets/images/avatar-no-gender.png";
import InfoGreen from "../../../assets/images/info_green.png";
import * as Icon from "../../../assets/svgs";

import {
  Maybe,
  ObjetoEducacionalEntity,
  PerfilDeAutorEntity,
  UsersPermissionsUserEntity,
  useSeguirMutation,
} from "../../../generated/graphql";

import { updateUserContext, UserContext } from "../../../context/UserContext";

import { If } from "../";

import { HEADER_ACTION_TYPE } from "../../constants/action-types";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";

import "./styles.scss";
import { useUtils } from "../../utils";
import ResetUserContext from "../../../hooks/ResetUserContext";
import LinkCustom from "../Link";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import { useHistory } from "react-router";

interface AuthorBoxProps {
  oe?: ObjetoEducacionalEntity;
  author: Maybe<PerfilDeAutorEntity>;
  isDetailPage?: boolean;
}

export const AuthorBox: React.FC<AuthorBoxProps> = (props) => {
  const { user, setUser } = useContext(UserContext);
  const { formatName } = useUtils();
  const [quantidade, setQuantidade] = useState<number>(0);
  const [visibleComConflito, setVisibleComConflito] = useState(false);
  const history = useHistory();

  const [following, setFollowing] = useState<boolean>(false);

  const [seguirMutation] = useSeguirMutation();

  const dispatch = useDispatch();
  const gtm = useGtm();

  const ehAutor = (seguindo: any, autor: PerfilDeAutorEntity) => {
    if (!seguindo || !autor) {
      return false;
    }

    return seguindo.id.toString() === autor.id?.toString();
  };

  useEffect(() => {
    if (!!user && user) {
      const following = user?.attributes?.Seguindo?.data.find((author) =>
        ehAutor(author, props.author),
      );

      if (!!following) {
        setFollowing(true);
      }
    }
  }, [props.author.id, user]);

  const handleToggleFollow = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    ev.stopPropagation();

    if (!following) {
      // GTM doc linha 73 e 76
      gtm.push(
        EDataLayerEventCategory[
          props.oe ? "ADE_OE_PAGINA" : "ADE_CENTRAL_AUTORIA"
        ],
        EDataLayerEventAction.CLIQUE,
        {
          label: props.oe
            ? `seguir:${props.author.attributes?.nome_exibicao}:${props.oe.attributes?.Titulo}`
            : `seguir:${props.author.attributes?.nome_exibicao}`,
        },
      );
    }

    if (!user || !user) {
      dispatch({
        type: HEADER_ACTION_TYPE.PRE_REGISTER_USER,
        payload: true,
      });
      return;
    }

    const hasFollower = user.attributes?.Seguindo?.data.find((author) =>
      ehAutor(author, props.author),
    );

    const otherFollowers = (
      user.attributes?.Seguindo?.data as PerfilDeAutorEntity[]
    )
      .filter((author) => !ehAutor(author, props.author))
      .map((author) => author?.id!);

    const newFollowers = !hasFollower
      ? [...otherFollowers, props.author.id!]
      : [...otherFollowers];

    seguirMutation({
      variables: {
        id: user.id!,
        input: {
          Seguindo: newFollowers,
        },
      },
    }).then(async () => {
      setFollowing(!hasFollower);

      const newUser = {
        id: user.id,
        attributes: {
          ...user.attributes,
          Seguindo: {
            __typename: "PerfilDeAutorRelationResponseCollection",
            data: newFollowers.reduce((acc: any[], id) => {
              const perfil: PerfilDeAutorEntity = {
                id: id,
              };
              acc.push(perfil);
              return acc;
            }, []) as PerfilDeAutorEntity[],
          },
        },
      };
      updateUserContext(newUser as UsersPermissionsUserEntity);
      setUser(newUser as UsersPermissionsUserEntity);
    });
  };

  const visualizarComConflito = () => {
    const timer = setTimeout(() => {
      setVisibleComConflito(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    const qtd = props?.author?.attributes?.Publicacoes
      ? props?.author?.attributes?.Publicacoes?.data.length
      : 0;
    setQuantidade(qtd);
  }, [props]);

  const fotoAutor =
    props.author.attributes?.url ??
    props.author.attributes?.foto?.data?.attributes?.url;

  function validaUrl(url: string) {
    try {
      new URL(url);
      return url;
    } catch (error) {
      return "https://" + url;
    }
  }

  return (
    props.author && (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4 px-2 py-3 md:flex-row md:!px-5 ">
        <div>
          <Avatar className="size-44 bg-white-200">
            <AvatarImage src={fotoAutor} alt="@shadcn" />
            <AvatarFallback>
              <Icon.SemFoto />
            </AvatarFallback>
          </Avatar>
        </div>

        <div className="flex w-full flex-col items-center justify-center py-2 md:h-full md:items-start">
          <div className="relative flex w-full flex-col items-center justify-center  text-center md:items-start md:text-start">
            <div className="flex gap-2 ">
              <div className="mt-2 w-full  md:mt-0  ">
                <h3 className=" ms:text-center relative inline-block flex-row  flex-nowrap text-base   font-bold text-[#0a589b] text-shadow-[#0a589b] md:text-start">
                  {formatName(props.author.attributes?.nome_exibicao!)}
                  <div className="absolute -right-7 top-0">
                    <div
                      className="cursor-pointer"
                      onClick={() => setVisibleComConflito(!visibleComConflito)}
                    >
                      <img src={InfoGreen} alt="vinculo-img" className="  " />
                    </div>
                  </div>
                </h3>
                {visibleComConflito && (
                  <div
                    className="shadow bg-white absolute left-0 right-0 top-8 z-10 m-auto w-80 rounded py-3"
                    onMouseLeave={() => visualizarComConflito()}
                  >
                    {props.author.attributes?.empresas_apoio &&
                    props.author.attributes?.com_conflito ? (
                      <p className="font-mono text-xs font-normal text-[#000000]">
                        Possuo vínculo e/ou recebi apoio financeiro da empresa
                        listada a seguir:{" "}
                        {props.author.attributes?.empresas_apoio}
                      </p>
                    ) : (
                      <p className="font-mono text-xs font-normal text-[#000000]">
                        Não possuo vínculo ou recebi apoio financeiro de
                        organizações externas.
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center text-center md:items-start  md:!text-start">
              <h4 className="mx-2 text-sm font-normal text-[#00498D] md:!mx-0">
                {props.author.attributes?.cargo}
              </h4>

              <div className="mt-6 flex flex-col items-center justify-center md:items-start">
                {props.author.attributes?.autoriza_divulgacao_link &&
                  props.author.attributes?.link_do_curriculo &&
                  props.author.attributes?.nome_exibicao && (
                    <div
                      className="flex w-full cursor-pointer items-center justify-center gap-3"
                      onClick={() => {
                        // GTM doc linha 74 e 77
                        gtm.push(
                          EDataLayerEventCategory[
                            props.oe ? "ADE_OE_PAGINA" : "ADE_CENTRAL_AUTORIA"
                          ],
                          EDataLayerEventAction.CLIQUE,
                          {
                            label: props.oe
                              ? `curriculo:${props.author.attributes?.nome_exibicao}:${props.oe.attributes?.Titulo}`
                              : `curriculo:${props.author.attributes?.nome_exibicao}`,
                          },
                        );

                        if (!!props.author.attributes?.link_do_curriculo) {
                          window.open(
                            validaUrl(
                              props?.author?.attributes?.link_do_curriculo!,
                            ),
                            "_blank",
                          );
                        }
                      }}
                    >
                      <img
                        src={AuthorLinkCurriculoBlue}
                        alt="Ícone de link"
                        className="h-[14px] w-[14px]"
                      />
                      <span className="inline-block text-xs font-normal leading-normal text-[#0A589B]">
                        Acessar Currículo
                      </span>
                    </div>
                  )}

                {props.author.attributes?.link_projeto &&
                  props.author.attributes?.nome_projeto && (
                    <div
                      data-author-projeto
                      data-author-item
                      onClick={() => {
                        if (!!props.author.attributes?.link_projeto) {
                          window.open(
                            props.author.attributes?.link_projeto,
                            "_blank",
                          );
                        }
                      }}
                      className="mt-1 flex gap-3"
                    >
                      <span className="inline-block flex-row text-xs font-normal leading-normal text-[#0A589B]">
                        <img
                          src={InternetIcone}
                          alt="Ícone de projeto"
                          height="14px"
                          style={{ height: "14px" }}
                        />
                      </span>
                      <span className=" inline-block flex-row text-xs font-normal leading-normal text-[#0A589B]">
                        {props.author.attributes?.nome_projeto}
                      </span>
                    </div>
                  )}
              </div>
            </div>

            {!!props.author.attributes?.Publicacoes?.data.length && (
              <div
                data-author-content-published
                className="mt-3 flex flex-row gap-3"
              >
                <img src={AuthorListContents} alt="Ícone de publicações" />
                <span className="flex-wrap text-xs font-normal">
                  {quantidade} Conteúdos Adicionados
                </span>
              </div>
            )}
          </div>

          <div className="hide-for-mobile mt-3 flex w-full gap-2">
            <Button
              size="sm"
              data-testid="btn-seguir-autor"
              className={`flex h-10 items-center justify-center rounded-full !text-xs ${
                following ? "bg-[#00B193]" : "bg-[#00498D]"
              } text-white w-auto  font-bold`}
              onClick={handleToggleFollow}
            >
              <If test={!following}>+ Seguir</If>
              <If test={!!following}>Deixar de seguir</If>
            </Button>

            {!props?.isDetailPage && (
              <Button
                data-testid="btn-ver-conteudos"
                variant="outline"
                className="h-10 px-2 text-sm"
                size="sm"
                onClick={() => {
                  history.push(`/autor/${props.author.id}/detalhes`);
                  // GTM doc linha 75 e 78
                  gtm.push(
                    EDataLayerEventCategory[
                      props.oe ? "ADE_OE_PAGINA" : "ADE_CENTRAL_AUTORIA"
                    ],
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: props.oe
                        ? `conteudo:${props.oe.attributes?.Titulo}:${props.author.attributes?.nome_exibicao}`
                        : `conteudo:${props.author.attributes?.nome_exibicao}`,
                    },
                  );
                }}
              >
                Ver conteúdos
              </Button>
            )}
          </div>
        </div>

        <div className="hide-for-desktop mb-2 flex gap-3 md:mt-9">
          <Button
            data-testid="btn-seguir-autor-mobile"
            size="sm"
            className="h-9"
            onClick={handleToggleFollow}
          >
            <If test={!following}>+ Seguir</If>
            <If test={!!following}>Seguindo</If>
          </Button>
          {!props?.isDetailPage && (
            <LinkCustom
              data-testid="btn-ver-conteudos-mobile"
              to={`/autor/${props.author.id}/detalhes`}
              className="flex h-9 w-[142px] items-center justify-center rounded-full border-2 border-[#0A589B] text-xs font-bold text-[#0A589B]"
              onClick={() => {
                // GTM doc linha 75 e 78
                gtm.push(
                  EDataLayerEventCategory[
                    props.oe ? "ADE_OE_PAGINA" : "ADE_CENTRAL_AUTORIA"
                  ],
                  EDataLayerEventAction.CLIQUE,
                  {
                    label: props.oe
                      ? `conteudo:${props.oe.attributes?.Titulo}:${props.author.attributes?.nome_exibicao}`
                      : `conteudo:${props.author.attributes?.nome_exibicao}`,
                  },
                );
              }}
            >
              Ver conteúdos
            </LinkCustom>
          )}
        </div>
      </div>
    )
  );
};
