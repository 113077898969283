import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Theme, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import InfoParceriaBrancoHover from "../../assets/svgs/info-parceria-branco-hover.svg";

import {
  Breadcrumb,
  BreadcrumbItem,
  CardSection,
  HeaderSection,
  Loader,
} from "../../shared/components";

import {
  CategoriaParceria,
  Parceria,
  ParceriaEntity,
  useGetCategoriaParceriaLazyQuery,
} from "../../generated/graphql";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";

export const CategoriaParceriasPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<CategoriaParceria[]>([]);
  const gtm = useGtm();
  const scrollTop = useScrollTop();

  const [GetCategoriasParcerias, { data: refecthCategorias }] =
    useGetCategoriaParceriaLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        if (response.categoriaParcerias) {
          setData(response.categoriaParcerias.data as CategoriaParceria[]);
        }

        setLoading(false);
      },
    });

  useEffect(() => {
    scrollTop();

    return () => {
      // GTM doc linha 64
      gtm.push(
        EDataLayerEventCategory.PAGE_VIEW,
        EDataLayerEventAction.NAVEGACAO,
        {
          label: "parcerias",
        },
      );
    };
  }, []);

  useEffect(() => {
    GetCategoriasParcerias();

    if (refecthCategorias) {
      setLoading(false);
      setData((data) => {
        const categorias = refecthCategorias.categoriaParcerias
          ?.data as CategoriaParceria[];

        data = [...categorias];

        return data;
      });
    }
  }, [GetCategoriasParcerias]);

  const criarLink = (parceria: ParceriaEntity) => {
    return `/parcerias/${parceria.attributes?.titulo
      .toLowerCase()
      .replace(/[" "]/g, "-")}/${parceria.id}`;
  };

  const existemOesEmParcerias = (categoria: CategoriaParceria) => {
    return (
      categoria.parcerias &&
      !!categoria.parcerias.data.filter(
        (p) => !!p.attributes?.objeto_educacionais?.data.length,
      ).length
    );
  };

  const existemOesEmParceria = (parceria: Parceria) => {
    return (
      !!parceria.objeto_educacionais &&
      !!parceria.objeto_educacionais.data.length
    );
  };

  const mapParcerias = (categoria: CategoriaParceria) => {
    return !!categoria.parcerias?.data ? categoria.parcerias.data : [];
  };

  const ParceriaTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fontSize: 12,
      fontStyle: "italic",
    },
  }))(Tooltip);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />

      <div data-area-patrocinador-page>
        <HeaderSection>
          <h2>Academia Digital Einstein</h2>
          <Breadcrumb>
            <BreadcrumbItem to="/">Home</BreadcrumbItem>
            <BreadcrumbItem active>Parcerias</BreadcrumbItem>
          </Breadcrumb>
          <div data-header-section-sub-title>
            <h3>Parcerias</h3>
            <ParceriaTooltip
              data-show-desktop
              title="Este conteúdo foi desenvolvido pela Sociedade Beneficente Israelita Brasileira Albert Einstein e tem por objetivo a difusão de conhecimento técnico-científico acerca do tema apresentado, contribuindo com o desenvolvimento das atividades de saúde. A Sociedade se compromete com a transparência e com a imparcialidade do conteúdo acadêmico, livre de qualquer propósito comercial ou vinculação com contratos de aquisição de produtos de eventual apoiador ou patrocinador."
              placement="bottom-start"
            >
              <img
                data-header-info
                src={InfoParceriaBrancoHover}
                alt="Icone parceria"
              />
            </ParceriaTooltip>
          </div>
        </HeaderSection>
        <section data-container>
          {data &&
            data.map((item, idx) => (
              <div key={idx}>
                {existemOesEmParcerias(item) && (
                  <>
                    {mapParcerias(item).map((parceria, idx2) => (
                      <div data-item-patrocinador key={idx2}>
                        {existemOesEmParceria(parceria.attributes!) && (
                          <>
                            {parceria.attributes?.thumbnail && (
                              <div data-patrocinador-logo>
                                <Link to={criarLink(parceria)}>
                                  <img
                                    height={
                                      parceria.attributes.thumbnail?.data
                                        ?.attributes?.height
                                    }
                                    style={{
                                      height:
                                        parceria.attributes.thumbnail?.data
                                          ?.attributes?.height,
                                    }}
                                    src={criarUrl(
                                      parceria?.attributes.thumbnail.data
                                        ?.attributes?.url,
                                    )}
                                    alt={parceria.attributes?.titulo}
                                  />
                                </Link>
                              </div>
                            )}
                            {parceria.attributes?.objeto_educacionais && (
                              <CardSection
                                title=""
                                items={
                                  parceria.attributes?.objeto_educacionais.data!
                                }
                                linkUrl={criarLink(parceria)}
                                showAllSee={true}
                                notLimit
                              ></CardSection>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            ))}
        </section>
      </div>
    </>
  );
};

export const criarUrl = (url?: string) => {
  url = url && !url.includes("http") ? `http://localhost:1337${url}` : url;

  return url;
};
