import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import { useFormik } from "formik";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import { dadosUsuarioValidator } from "./validator/dados-usuario.validator";

import {
  useGtm,
  EDataLayerEventAction,
  EDataLayerEventCategory,
} from "../../../../hooks/useGtm";
import { useNovoUsuario } from "../../../../hooks/useNovoUsuario";

import "./styles.scss";
import { twMerge } from "tailwind-merge";
import { Button } from "../../../../shared/components/ui/button";
import { Input } from "../../../../shared/components/ui/input";
import { CircleCheck, EyeIcon, EyeOff, Loader2 } from "lucide-react";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../shared/components/ui/radio-group";
import { Label } from "../../../../shared/components/ui/label";

export interface DadosPessoaisInterface {
  nome_completo: string;
  nome_exibicao: string;
  data_nascimento: string;
  toggleOtherDocument: boolean;
  cpf: string;
  email: string;
  username?: string;
  documentType: string;
  documentNumber: string;
  toggleInternationalTelphone: boolean;
  celular: string;
  password: string;
  confirmPassword: string;
  formStep: number;
  deficiencia_auditiva: boolean;
  deficiencia_visual: boolean;
  deficiencia_fisica: boolean;
  deficiencia_intelectual_mental: boolean;
  deficiencia_psicossocial_autismo: boolean;
  deficiencia_outras: boolean;
  possui_deficiencia: PossuiDeficienciaInterface;
  provider?: string;
}

enum PossuiDeficienciaInterface {
  NAO_SOU_PESSOA_COM_DEFICIENCIA = "nao_sou_pessoa_com_deficiencia",
  SOU_PESSOA_COM_DEFICIENCIA = "sou_pessoa_com_deficiencia",
  PREFIRO_NAO_INFORMAR = "prefiro_nao_informar",
}

export const DadosPessoaisV2: React.FC = () => {
  const gtm = useGtm();
  const history = useHistory();
  const localUser = useNovoUsuario<DadosPessoaisInterface>();
  const [showAllErrorsMessages, setShowAllErrorsMessages] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik<DadosPessoaisInterface>({
    initialValues: {
      nome_completo: "",
      nome_exibicao: "",
      data_nascimento: "",
      toggleOtherDocument: false,
      cpf: "",
      email: "",
      documentType: "",
      documentNumber: "",
      toggleInternationalTelphone: false,
      celular: "",
      password: "",
      confirmPassword: "",
      formStep: 1,
      deficiencia_auditiva: false,
      deficiencia_visual: false,
      deficiencia_fisica: false,
      deficiencia_intelectual_mental: false,
      deficiencia_psicossocial_autismo: false,
      deficiencia_outras: false,
      possui_deficiencia:
        PossuiDeficienciaInterface.NAO_SOU_PESSOA_COM_DEFICIENCIA,
    },
    validationSchema: dadosUsuarioValidator,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      gtm.push(
        EDataLayerEventCategory.ADE_CADASTRO,
        EDataLayerEventAction.CLIQUE,
        {
          label: "cadastro-avançar1",
        },
      );

      values.username = values.email.trim().toLowerCase();
      values.email = values.email.trim().toLowerCase();
      values.formStep = 2;
      localUser.save(values);
      history.push("/novo-usuario-v2/dados-profissionais");
    },
  });

  useEffect(() => {
    localUser.load().then((user) => {
      if (user) {
        console.log("user", user);
        formik.setValues(user);
      }
    });
  }, []);

  const handleKeyDown = (e: any) => {
    if (e.nativeEvent.key == "Enter") {
      const form = e.target.form;
      const formFields = [...form.elements];
      const formFieldsWithoutButton = formFields.filter(
        (element) => element.type !== "button",
      );
      const index = [...formFieldsWithoutButton].indexOf(e.target);

      formFieldsWithoutButton[index + 1].focus();

      e.preventDefault();
    }
  };

  function sendEmail(emailAddress: string) {
    window.location.href = `mailto:${emailAddress}`;
  }

  return (
    <>
      <section className="flex justify-center">
        <form
          onSubmit={formik.handleSubmit}
          className="flex w-full flex-col items-center justify-center rounded-2xl md:w-[956px] md:border md:py-10"
        >
          <h3 className="mb-9 hidden text-center text-xl font-semibold text-[#004F92] md:flex">
            Informações Pessoais
          </h3>
          <div className="flex flex-col items-start justify-center gap-10 md:flex-row">
            <div className="flex w-full flex-col gap-3 md:min-w-[380px] ">
              <Input
                label="Nome completo*"
                id="nome_completo"
                name="nome_completo"
                onChange={formik.handleChange}
                value={formik.values.nome_completo}
                errorMessage={
                  formik.touched.nome_completo
                    ? formik.errors.nome_completo
                    : ""
                }
                placeholder="Digite seu nome completo"
              />

              <Input
                label="Como prefere ser chamado/a?"
                id="nome_exibicao"
                name="nome_exibicao"
                onChange={formik.handleChange}
                value={formik.values.nome_exibicao}
                errorMessage={formik.errors.nome_exibicao}
                placeholder="Digite como prefere ser chamado"
              />
              <InputMask
                mask="99/99/9999"
                onChange={formik.handleChange}
                value={formik.values.data_nascimento}
                placeholder="dd/mm/aa"
              >
                <Input
                  label="Data de nascimento*"
                  id="data_nascimento"
                  name="data_nascimento"
                  onChange={formik.handleChange}
                  value={formik.values.data_nascimento}
                  errorMessage={formik.errors.data_nascimento}
                />
              </InputMask>
              <div className="flex w-full flex-col">
                {!formik.values.toggleOtherDocument && (
                  <InputMask
                    mask="999.999.999-99"
                    onChange={formik.handleChange}
                    value={formik.values.cpf}
                    placeholder="999.999.999-99"
                  >
                    <Input
                      label="CPF*"
                      id="cpf"
                      name="cpf"
                      onChange={formik.handleChange}
                      onKeyPress={handleKeyDown}
                      value={formik.values.cpf}
                      errorMessage={formik.errors.cpf}
                      placeholder="Digite seu CPF"
                    />
                  </InputMask>
                )}
                {formik.values.toggleOtherDocument && (
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col">
                      <Input
                        label="Nome do documento"
                        id="documentType"
                        name="documentType"
                        onChange={formik.handleChange}
                        onKeyPress={handleKeyDown}
                        value={formik.values.documentType}
                        errorMessage={formik.errors.documentType}
                        placeholder="Digite o nome do documento"
                      />
                    </div>

                    <div className="flex flex-col">
                      <Input
                        label="Numero do documento"
                        id="documentNumber"
                        name="documentNumber"
                        onChange={formik.handleChange}
                        onKeyPress={handleKeyDown}
                        value={formik.values.documentNumber}
                        errorMessage={formik.errors.documentNumber}
                        placeholder="Digite o número do documento"
                      />
                    </div>
                  </div>
                )}
                {!formik.values.toggleOtherDocument && (
                  <div
                    onClick={(ev) => {
                      formik.setValues({
                        ...formik.values,
                        toggleOtherDocument: !formik.values.toggleOtherDocument,
                        cpf: "",
                      });
                    }}
                    className="mt-1 flex w-full justify-end"
                  >
                    <span className="cursor-pointer text-end text-xs font-semibold text-[#004F92] underline">
                      Não tenho CPF
                    </span>
                  </div>
                )}
                {formik.values.toggleOtherDocument && (
                  <a
                    onClick={(ev) => {
                      formik.setValues({
                        ...formik.values,
                        toggleOtherDocument: !formik.values.toggleOtherDocument,
                        documentType: "",
                        documentNumber: "",
                      });
                    }}
                    className="mt-1 flex justify-end text-end"
                  >
                    <span className="cursor-pointer text-end text-xs font-semibold text-[#004F92] underline">
                      Tenho CPF
                    </span>
                  </a>
                )}
              </div>

              {formik.values.toggleInternationalTelphone ? (
                <>
                  <label className="select-none text-sm font-normal text-[#111316]">
                    Telefone internacional
                  </label>
                  <PhoneInput
                    defaultCountry="us"
                    name="celular"
                    onChange={(phone) => {
                      formik.setFieldValue("celular", phone);
                    }}
                    className="border-input ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-[3.125rem] w-full rounded-[30px]  border bg-[#FAFAFC] px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                    value={formik.values.celular}
                  />
                  <label className="text-xs text-red-500">
                    {formik.touched &&
                      (formik.values.celular || showAllErrorsMessages) &&
                      formik.errors.celular}
                  </label>
                </>
              ) : (
                <>
                  <InputMask
                    mask={
                      formik.values.toggleInternationalTelphone
                        ? "+** 999 999 9999"
                        : "(99) 9 9999-9999"
                    }
                    onChange={formik.handleChange}
                    value={formik.values.celular}
                    placeholder="(99) 9.9999-9999"
                  >
                    <Input
                      label={
                        formik.values.toggleInternationalTelphone
                          ? "Telefone internacional*"
                          : "Celular*"
                      }
                      id="celular"
                      name="celular"
                      type="text"
                      onChange={formik.handleChange}
                      onKeyPress={handleKeyDown}
                      value={formik.values.celular}
                      errorMessage={formik.errors.celular}
                      placeholder={
                        formik.values.toggleInternationalTelphone
                          ? "Digite seu telefone internacional"
                          : "Digite seu celular"
                      }
                    />
                  </InputMask>
                </>
              )}
              <a
                className="flex justify-end text-end"
                onClick={(ev) => {
                  formik.setValues({
                    ...formik.values,
                    toggleInternationalTelphone:
                      !formik.values.toggleInternationalTelphone,
                    celular: "",
                  });
                  ev.stopPropagation();
                }}
              >
                {!formik.values.toggleInternationalTelphone && (
                  <span className="-mt-3 cursor-pointer text-end text-xs font-semibold text-[#004F92] underline">
                    Não tenho telefone do país (Brasil)
                  </span>
                )}
                {formik.values.toggleInternationalTelphone && (
                  <span className="cursor-pointer text-end text-xs font-semibold text-[#004F92] underline">
                    Tenho telefone do país (Brasil)
                  </span>
                )}
              </a>
            </div>

            <div className="flex w-auto flex-col gap-3 md:min-w-[380px] ">
              <Input
                label="Endereço de e-mail*"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                errorMessage={formik.touched.email ? formik.errors.email : ""}
                placeholder="Digite seu e-mail"
              />
              <Input
                label="Criar uma senha*"
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={formik.handleChange}
                value={formik.values.password}
                errorMessage={formik.errors.password}
                placeholder="Insira sua senha"
                icon={
                  showPassword ? (
                    <EyeIcon
                      className="cursor-pointer text-[#004F92]"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <EyeOff
                      className="cursor-pointer text-[#004F92]"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )
                }
              />

              <Input
                label="Confirmar sua senha*"
                id="confirmPassword"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                errorMessage={formik.errors.confirmPassword}
                placeholder="Confirme sua senha"
                icon={
                  showConfirmPassword ? (
                    <EyeIcon
                      className="cursor-pointer text-[#004F92]"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  ) : (
                    <EyeOff
                      className="cursor-pointer text-[#004F92]"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  )
                }
              />

              <div className="mt-2 flex flex-col items-start justify-start text-start">
                <p className="mb-3 text-sm font-bold">
                  Sua senha deve conter no mínimo:
                </p>
                <p
                  className={twMerge(
                    "flex items-center justify-center gap-2 text-sm font-medium",
                    (formik.values.password &&
                      formik.values.password.length >= 8) ||
                      formik.values.password.length === 0
                      ? "text-black-900"
                      : "text-[#D64214]",
                  )}
                >
                  <CircleCheck
                    width={18}
                    color="#21AB27"
                    className={twMerge(
                      formik.values.password &&
                        formik.values.password.length >= 8
                        ? ""
                        : "hidden",
                    )}
                  />
                  Necessário ao menos 8 caracteres
                </p>
                <p
                  className={twMerge(
                    "flex items-center justify-center gap-2 text-sm font-medium",
                    (formik.values.password &&
                      /[a-z]/.test(formik.values.password)) ||
                      formik.values.password.length === 0
                      ? "text-black-900"
                      : "text-[#D64214]",
                  )}
                >
                  <CircleCheck
                    width={18}
                    color="#21AB27"
                    className={twMerge(
                      formik.values.password &&
                        /[a-z]/.test(formik.values.password)
                        ? ""
                        : "hidden",
                    )}
                  />
                  1 caractere minúsculo
                </p>
                <p
                  className={twMerge(
                    "flex items-center justify-center gap-2 text-sm font-medium",
                    (formik.values.password &&
                      /[A-Z]/.test(formik.values.password)) ||
                      formik.values.password.length === 0
                      ? "text-black-900"
                      : "text-[#D64214]",
                  )}
                >
                  <CircleCheck
                    width={18}
                    color="#21AB27"
                    className={twMerge(
                      formik.values.password &&
                        /[A-Z]/.test(formik.values.password)
                        ? ""
                        : "hidden",
                    )}
                  />
                  1 caractere maiúsculo
                </p>
                <p
                  className={twMerge(
                    "flex items-center justify-center gap-2 text-sm font-medium",
                    (formik.values.password &&
                      /[0-9]/.test(formik.values.password)) ||
                      formik.values.password.length === 0
                      ? "text-black-900"
                      : "text-[#D64214]",
                  )}
                >
                  <CircleCheck
                    width={18}
                    color="#21AB27"
                    className={twMerge(
                      formik.values.password &&
                        /[0-9]/.test(formik.values.password)
                        ? ""
                        : "hidden",
                    )}
                  />
                  1 caractere numérico
                </p>
                <p
                  className={twMerge(
                    "flex items-center justify-center gap-2 text-sm font-medium",
                    (formik.values.password &&
                      /[!@#$%^&*(),.?":{}|<>]/.test(formik.values.password)) ||
                      formik.values.password.length === 0
                      ? "text-black-900"
                      : "text-[#D64214]",
                  )}
                >
                  <CircleCheck
                    width={18}
                    color="#21AB27"
                    className={twMerge(
                      formik.values.password &&
                        /[^a-zA-Z0-9]/.test(formik.values.password)
                        ? ""
                        : "hidden",
                    )}
                  />
                  1 caractere especial (pontuação ou outro símbolo)
                </p>
              </div>
              <div className="mt-3 flex flex-col gap-3">
                <span className="select-none text-sm font-normal leading-4 text-black-900">
                  Pessoa com deficiência?
                </span>
                <RadioGroup
                  defaultValue={formik.values.possui_deficiencia}
                  onValueChange={(value) => {
                    console.log("value", value);
                    formik.setValues({
                      ...formik.values,
                      deficiencia_auditiva: false,
                      deficiencia_visual: false,
                      deficiencia_fisica: false,
                      deficiencia_intelectual_mental: false,
                      deficiencia_psicossocial_autismo: false,
                    });

                    formik.setFieldValue("possui_deficiencia", value);
                  }}
                  className="accent-[#004F92]"
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem
                      value={
                        PossuiDeficienciaInterface.NAO_SOU_PESSOA_COM_DEFICIENCIA
                      }
                      className="accent-[#004F92]"
                    />
                    <Label
                      htmlFor="r1"
                      className={twMerge(
                        "ml-3 text-xs font-medium leading-4 ",
                        formik.values.possui_deficiencia ===
                          PossuiDeficienciaInterface.NAO_SOU_PESSOA_COM_DEFICIENCIA
                          ? "font-semibold text-[#004F92]"
                          : "font-semibold text-[#626466]",
                      )}
                    >
                      Não sou uma pessoa com deficiência
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem
                      value={
                        PossuiDeficienciaInterface.SOU_PESSOA_COM_DEFICIENCIA
                      }
                      id="r2"
                    />
                    <Label
                      htmlFor="r2"
                      className={twMerge(
                        "ml-3 text-xs font-medium leading-4",
                        formik.values.possui_deficiencia ===
                          PossuiDeficienciaInterface.SOU_PESSOA_COM_DEFICIENCIA
                          ? "font-semibold text-[#004F92]"
                          : "font-semibold text-[#626466]",
                      )}
                    >
                      Sim, sou uma pessoa com deficiência
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem
                      value={PossuiDeficienciaInterface.PREFIRO_NAO_INFORMAR}
                      id="r3"
                    />
                    <Label
                      htmlFor="r3"
                      className={twMerge(
                        "ml-3 text-xs font-medium leading-4",
                        formik.values.possui_deficiencia ===
                          PossuiDeficienciaInterface.PREFIRO_NAO_INFORMAR
                          ? "font-semibold text-[#004F92]"
                          : "font-semibold text-[#626466]",
                      )}
                    >
                      Prefiro não informar
                    </Label>
                  </div>
                </RadioGroup>
              </div>
              {formik.values.possui_deficiencia ===
                PossuiDeficienciaInterface.SOU_PESSOA_COM_DEFICIENCIA && (
                <div className="mt-2 w-full">
                  <span className="text-sm font-normal leading-4 text-black-900">
                    Qual?
                  </span>

                  <div className="mt-3 flex items-center">
                    <input
                      type="checkbox"
                      name="deficiencia_auditiva"
                      id="deficiencia_auditiva"
                      value="deficiencia_auditiva"
                      checked={formik.values.deficiencia_auditiva}
                      onChange={formik.handleChange}
                      className="h-4 w-4 cursor-pointer accent-[#004F92]"
                    />
                    <label
                      htmlFor="deficiencia_auditiva"
                      className={twMerge(
                        "ml-2 text-xs font-medium leading-4",
                        formik.values.deficiencia_auditiva
                          ? "font-semibold text-[#004F92]"
                          : "font-semibold text-[#626466]",
                      )}
                    >
                      Pessoa com deficiência auditiva
                    </label>
                  </div>

                  <div className="mt-3  flex items-center">
                    <input
                      type="checkbox"
                      name="deficiencia_visual"
                      id="deficiencia_visual"
                      value="deficiencia_visual"
                      checked={formik.values.deficiencia_visual}
                      onChange={formik.handleChange}
                      className="h-4 w-4 cursor-pointer accent-[#004F92] "
                    />
                    <label
                      htmlFor="deficiencia_visual"
                      className={twMerge(
                        "ml-2 text-xs font-medium leading-4",
                        formik.values.deficiencia_visual
                          ? "font-semibold text-[#004F92]"
                          : "font-semibold text-[#626466]",
                      )}
                    >
                      Pessoa com deficiência visual
                    </label>
                  </div>

                  <div className="mt-3  flex items-center">
                    <input
                      type="checkbox"
                      name="deficiencia_fisica"
                      id="deficiencia_fisica"
                      value="deficiencia_fisica"
                      checked={formik.values.deficiencia_fisica}
                      onChange={formik.handleChange}
                      className="h-4 w-4 cursor-pointer accent-[#004F92]"
                    />
                    <label
                      htmlFor="deficiencia_fisica"
                      className={twMerge(
                        "ml-2 text-xs font-medium leading-4",
                        formik.values.deficiencia_fisica
                          ? "font-semibold text-[#004F92]"
                          : "font-semibold text-[#626466]",
                      )}
                    >
                      Pessoa com deficiência física
                    </label>
                  </div>

                  <div className="mt-3  flex items-center">
                    <input
                      type="checkbox"
                      name="deficiencia_intelectual_mental"
                      id="deficiencia_intelectual_mental"
                      value="deficiencia_intelectual_mental"
                      checked={formik.values.deficiencia_intelectual_mental}
                      onChange={formik.handleChange}
                      className="h-4 w-4 cursor-pointer accent-[#004F92]"
                    />
                    <label
                      htmlFor="deficiencia_intelectual_mental"
                      className={twMerge(
                        "ml-2 text-xs font-medium leading-4",
                        formik.values.deficiencia_intelectual_mental
                          ? "font-semibold text-[#004F92]"
                          : "font-semibold text-[#626466]",
                      )}
                    >
                      Pessoa com deficiência intelectual/mental
                    </label>
                  </div>

                  <div className="mt-3  flex items-center">
                    <input
                      type="checkbox"
                      name="deficiencia_psicossocial_autismo"
                      id="deficiencia_psicossocial_autismo"
                      value="deficiencia_psicossocial_autismo"
                      checked={formik.values.deficiencia_psicossocial_autismo}
                      onChange={formik.handleChange}
                      className="h-4 w-4 cursor-pointer accent-[#004F92]"
                    />
                    <label
                      htmlFor="deficiencia_psicossocial_autismo"
                      className={twMerge(
                        "ml-2 text-xs font-medium leading-4",
                        formik.values.deficiencia_psicossocial_autismo
                          ? "font-semibold text-[#004F92]"
                          : "font-semibold text-[#626466]",
                      )}
                    >
                      Pessoa com deficiência psicossocial (Autismo)
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className=" mt-9 flex flex-col items-center justify-center gap-3 ">
            <Button
              data-testid="btn-avancar"
              onClick={() => setShowAllErrorsMessages(true)}
              variant="primary"
              size="default"
              type="submit"
              className="w-[147px]"
              disabled={formik.isSubmitting || formik.isValidating}
            >
              {formik.isSubmitting || formik.isValidating ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                "Avançar"
              )}
            </Button>

            <span className="text-center text-sm font-medium leading-4 text-[#111316] md:text-start">
              Problemas no cadastro? <br />
              Envie email para{" "}
              <span
                className="cursor-pointer text-[#004F92] underline"
                onClick={() => sendEmail("academia.digital@einstein.br")}
              >
                academia.digital@einstein.br
              </span>
            </span>
          </div>
        </form>
      </section>
    </>
  );
};
