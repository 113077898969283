import BannerAbaixoCabecalho from "./banner-abaixo-cabecalho";
import BannerTopo from "./banner-topo";
import Contadores from "./contadores";
import ConteudosInteresse from "./conteudos-interesse";
import ContinueAssistindo from "./continue-assistindo";
import DestaqueSemana from "./destaque-semana";
import IndicacoesConteudos from "./indicacoes-conteudos";
import SuasAreasInteresse from "./suas-areas-interesse";
import PlaylistsHome from "./playlists";

export default function Autenticado() {
  return (
    <div className="min-h-screen">
      <DestaqueSemana />
      <BannerAbaixoCabecalho />
      <PlaylistsHome />
      <ContinueAssistindo />
      <ConteudosInteresse />
      <BannerTopo />
      <SuasAreasInteresse />
      <IndicacoesConteudos />
      <Contadores />
    </div>
  );
}
