import React from "react";
import { Tooltip } from "@material-ui/core";
import { format, isFuture } from "date-fns";

import {
  ObjetoEducacional,
  ObjetoEducacionalEntity,
  ObjetoEducacionalHome,
} from "../../../../../../generated/graphql";

import {
  SmartLink,
  DateView,
  RotuloCategoria,
} from "../../../../../../shared/components";
import FooterOeMedia from "../../../../../../shared/components/OeMedia/FooterOeMedia";

import { splitOeTitle } from "../../../../../../helpers/oe-card.helper";
import HourWrite from "../../../../../../assets/svgs/hour-write.svg";
import CalendarWhite from "../../../../../../assets/svgs/calendar-white.svg";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../../../hooks/useGtm";

import "./styles.scss";
import classNames from "classnames";
import { ptBR } from "date-fns/locale";
import { ObjetoEducacionalVideo } from "../../../../../OEPage/OeVideoPage";
import { SmartLinkHome } from "../../../SmartLinkHome";
import { useUtils } from "../../../../../../shared/utils";

interface DestakCarouselItemProps {
  sliderWidth?: number;
  item: ObjetoEducacionalEntity;
  gtm?: any;
}

const DestakCarouselItemComponentHOC: React.FC<DestakCarouselItemProps> = (
  props,
) => {
  const gtm = useGtm();
  return <DestakCarouselItemComponent {...{ ...props, gtm }} />;
};

class DestakCarouselItemComponent extends React.Component<
  DestakCarouselItemProps,
  { estaAoVivo: boolean }
> {
  atualizarAoVivo() {
    this.setState({
      estaAoVivo: !isFuture(new Date(this.props.item.attributes?.DataHoraLive)),
    });
    setTimeout(() => this.atualizarAoVivo(), 1000);
  }

  constructor(props: DestakCarouselItemProps) {
    super(props);

    this.state = {
      estaAoVivo: false,
    };
  }

  componentDidMount() {
    this.atualizarAoVivo();
  }

  substringDescription(value: string) {
    return `${value}`;
  }

  formatarUrl(oeType: string, oeId: string, videoId?: string) {
    switch (oeType) {
      case "Áudio":
        return `/oe/${oeId}/podcast/`;
      case "Imagem":
        return `/oe/${oeId}/imagem/preview`;
      case "Questionário":
      case "Quizz":
        return `/oe/${oeId}/quiz`;
      case "Simulação":
        return `/oe/${oeId}/simulacao/`;
      case "Leitura":
      case "Texto":
        return `/oe/${oeId}/leitura/preview/`;
      case "Live":
        return `/oe/${oeId}/live${!!videoId ? `/${videoId}` : ""}`;
      case "Vídeo":
        return `/oe/${oeId}/video${!!videoId ? `/${videoId}` : ""}`;
      default:
        return "";
    }
  }

  getUrl(oe: any) {
    const oeType = (oe.TipoObjeto && oe.TipoObjeto[0]?.titulo) || "";
    let _url = this.formatarUrl(oeType, oe.id);

    if (!!(oe as ObjetoEducacionalVideo).VideoId) {
      _url = this.formatarUrl(
        oeType,
        oe.id,
        (oe as ObjetoEducacionalVideo).VideoId,
      );
    }

    return _url;
  }

  render() {
    const { item } = this.props;

    const isPatrocinador = item?.attributes?.patrocinador?.data
      ? (item?.attributes?.patrocinador as any)?.data.id > 0
      : (item?.attributes?.patrocinador as any).id > 0;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { formatTitle } = useUtils();

    return (
      <div
        data-destaque
        className={classNames("flex w-full flex-col rounded-2xl bg-[#6d6e70]", {
          "h-[280px] border-4 border-[#6d6e70] md:h-[360px]": isPatrocinador,
          "h-[280px] md:h-[360px]": !isPatrocinador,
        })}
      >
        <div
          className="relative h-full w-full rounded-2xl bg-cover bg-center p-7 md:p-[1.875rem]"
          style={{
            backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.15) 0%, rgba(0,0,0,.4) 100%), 
              url(${item.attributes?.ImagemPreview?.data?.attributes?.url})
            `,
          }}
        >
          <SmartLink oe={item}>
            <div className="h-full">
              <Tooltip title={formatTitle(item?.attributes?.Titulo!)}>
                <h3
                  className="mb-2 flex max-w-[210px] text-ssm font-semibold md:max-w-[240px]"
                  onClick={() => {
                    this.props.gtm?.push(
                      EDataLayerEventCategory.ADE_DESTAQUE,
                      EDataLayerEventAction.CLIQUE,
                      {
                        label: splitOeTitle(
                          formatTitle(item.attributes?.Titulo!),
                        ),
                      },
                    );
                  }}
                >
                  {splitOeTitle(formatTitle(item.attributes?.Titulo!))}
                </h3>
              </Tooltip>

              <div
                data-flex
                data-flex-wrap
                data-width="50"
                className={classNames({
                  "data-live": !!item.attributes?.TipoObjeto?.data?.find(
                    (p) => p.attributes?.titulo === "Live",
                  ),
                })}
              >
                {!!item.attributes?.TipoObjeto?.data?.find(
                  (p) => p.attributes?.titulo === "Live",
                ) &&
                  (!this.state?.estaAoVivo ? (
                    <div data-preview-em-breve-desktop>
                      <span>{`● EM BREVE`}</span>
                    </div>
                  ) : (
                    <div data-preview-aovivo-desktop>
                      <span>{`● AO VIVO`}</span>
                    </div>
                  ))}

                <div className="media-card__container">
                  {item?.attributes?.Rotulos &&
                    item.attributes?.TipoObjeto?.data?.find(
                      (p) => p.attributes?.titulo !== "Live",
                    ) &&
                    item?.attributes?.Rotulos.data?.map((item, idx) => {
                      return (
                        <RotuloCategoria
                          key={idx}
                          data={item!.attributes?.titulo ?? ""}
                        />
                      );
                    })}

                  {!!item.attributes?.TipoObjeto?.data.find(
                    (p) => p.attributes?.titulo === "Live",
                  ) ? (
                    <div data-hora-live>
                      <div className="mr-1 mt-2 flex justify-end">
                        <img
                          className="date-icon-calendar"
                          src={CalendarWhite}
                          alt="Icone de calendário"
                        />
                      </div>

                      <div style={{ color: "white" }}>
                        <span>
                          {item?.attributes?.DataHoraLive &&
                            format(
                              new Date(item?.attributes?.DataHoraLive),
                              "dd 'DE' MMMM",
                              {
                                locale: ptBR,
                              },
                            ).toUpperCase()}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <DateView
                      color="white"
                      date={item.attributes?.DataAtualizacaoProducao}
                    />
                  )}
                </div>

                {!!item.attributes?.TipoObjeto?.data?.find(
                  (p) => p.attributes?.titulo === "Live",
                ) && (
                  <div data-hora-live>
                    <img
                      className="date-icon"
                      src={HourWrite}
                      alt="Icone de hora"
                    />
                    <div style={{ color: "white" }}>
                      <span>
                        {item?.attributes?.DataHoraLive &&
                          format(
                            new Date(item?.attributes?.DataHoraLive),
                            "HH:mm 'H'",
                            {
                              locale: ptBR,
                            },
                          ).toUpperCase()}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {item.attributes?.TipoObjeto?.data?.find(
                (p) => p.attributes?.titulo !== "Live",
              ) && (
                <p
                  className={`text-[11px] ${
                    isPatrocinador
                      ? "line-clamp-3 leading-normal md:line-clamp-none md:leading-normal"
                      : "leading-normal"
                  } text-white mt-2 max-w-[200px] font-normal leading-normal md:text-[14px]`}
                  data-width="60"
                >
                  {item?.attributes?.Resumo}
                </p>
              )}
            </div>
          </SmartLink>
          <FooterOeMedia
            item={{
              Descricao: "",
              id: item.id!,
              imagemPreview:
                item.attributes?.ImagemPreview?.data?.attributes?.url!,
              Infografico: [],
              PossuiCertificado: item.attributes?.PossuiCertificado!,
              SecoesTexto: [],
              TempoMedioDuracao: item.attributes?.TempoMedioDuracao!,
              TipoObjeto:
                item.attributes?.TipoObjeto?.data?.map((a) => ({
                  titulo: a.attributes?.titulo!,
                })) || [],
              titulo: splitOeTitle(item.attributes?.Titulo!),
              VideoAudio:
                item.attributes?.VideoAudio?.map((a) => ({
                  id: Number.parseInt(a.id!),
                })) || [],
              VideoId:
                item.attributes?.VideoAudio &&
                item.attributes?.VideoAudio?.length > 0
                  ? Number.parseInt(item.attributes?.VideoAudio[0].id!)
                  : 0,
            }}
            color="white"
            padding={[16]}
            gradient={!!item.attributes?.patrocinador?.data?.id}
            shareUrl={this.getUrl(item)}
            estaAoVivo={this.state.estaAoVivo}
          />
        </div>

        {isPatrocinador && (
          <div className="relative flex h-7 w-full rounded-2xl bg-[#6d6e70]">
            <span className="text-white flex items-center px-2 text-[10px] font-bold">
              Conteúdo Patrocinado
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default DestakCarouselItemComponentHOC;
